import axios from "axios";

import { RootState } from "./../../rootReducer";

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Profile } from "../../../@types/profile";

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (formData: object, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { id } = state.profileDetail.profile;

      const response = await axios.patch(
        `${api.baseURL}profile/${id}`,
        formData
      );
      const data: Profile = response.data;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

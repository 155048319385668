import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonText, IonButton, IonList, IonItem, IonLabel } from "@ionic/react"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { importUserOrganization } from "../../../store/userCreate/actions/importUserOrganization";
import { resetUsersList } from "../../../store/users";
import findUsers from "../../../store/users/actions/findUsers";

export const ImportUserModal: React.FC<{ isOpen: boolean, importedUser:any }> = ({ isOpen, importedUser }) => {
    
    const dispatch = useDispatch();
    const history = useHistory()

    const user = useSelector(selectUser);
    const organizationId:any = user?.workingOrganization;

    const usuario = importedUser?.user;
    const idUsuario = importedUser?.user?.id;
    const idOrganization = importedUser?.organization;
    const [openModal, setOpenModal] = useState(isOpen);

    const importarUsuario = async () => {
        const data = {
            userId: idUsuario,
            sourceOrganization: idOrganization
        };
        const { payload:UserImported }:any = await dispatch(importUserOrganization(data));
        if(UserImported){
            setOpenModal(false);
            dispatch(resetUsersList())
            dispatch(findUsers(organizationId));
            history.replace(`/app/administrador/usuarios/${UserImported.user}`);
        };
    };
    
    return (
        <IonModal isOpen={openModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Importar Usuario</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="ion-text-center ion-padding">
                    <IonText style={{fontSize:20, fontWeight:600}}>Datos de usuario encontrado</IonText>
                </div>
                <IonList class="ion-padding">
                    <IonItem lines="full">
                        <IonLabel slot="start">Rut:</IonLabel>
                        <IonLabel slot="end">{usuario?.dni}</IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel slot="start">Nombre:</IonLabel>
                        <IonLabel slot="end">{usuario?.name}</IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel slot="start">Correo:</IonLabel>
                        <IonLabel slot="end">{usuario?.email}</IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel slot="start">Telefono:</IonLabel>
                        <IonLabel slot="end">{usuario?.celular}</IonLabel>
                    </IonItem>
                </IonList>
                <div className="ion-text-center" style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "20px" }}>
                    <IonButton color="success" onClick={() => importarUsuario()}>Importar Usuario</IonButton>
                    <IonButton color="danger" onClick={() => setOpenModal(false)}>Cancelar</IonButton>
                </div>
            </IonContent>
        </IonModal>
    )
}
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonCard,
  IonFooter,
  IonButtons,
  IonToolbar,
  IonToggle,
  IonIcon,
} from "@ionic/react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { User } from "../../../@types/user";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { createUser } from "../../../store/userCreate/actions/createUser";
import { selectUserCreateStatus } from "../../../store/userCreate/selectors/selectUserCreateStatus";
import { validate, format } from "rut.js";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { selectCreateUser } from "../../../store/userCreate/selectors/selectCreateUser";
import { findUserOrganization } from "../../../store/userCreate/actions/findUserOrganization";
import { checkmarkDoneOutline } from "ionicons/icons";
import { ImportUserModal } from "./ImportUserModal";

export const UserCreateForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const createStatus = useSelector(selectUserCreateStatus);
  const user = useSelector(selectUser);
  const organizationId: any = user?.workingOrganization;
  const formSubmit = useRef<any>(null);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      dni: "",
      celular: "",
      isSignatureEnabled: false,
    },
  });

  const [validating, setValidating] = useState(false);

  const [stateNewUser, setstateNewUser] = useState(false);

  const [isUserExistModalOpen, setIsUserExistModalOpen] = useState(false);

  const [importedUser, setImportedUser] = useState();

  const onValidateRut = (value: any) => {
    setValidating(true);
    setstateNewUser(false);
    setIsUserExistModalOpen(false);
    const info: any = { dni: value, organizationId };
    setTimeout(async () => {
      if (validate(value)) {
        //console.log('valido');
        clearErrors("dni");

        const { payload: importUser }: any = await dispatch(findUserOrganization(info));

        //console.log('importUser',importUser);

        if (importUser.user && importUser.organization) {
          //console.log('usuario existe en la organizacion');
          setError("dni", { message: "El usuario ya existe en esta organización." });
        } else if (importUser.user && importUser.organization == null) {
          //console.log('usuario existe pero no en esta organizacion');
          clearErrors("dni");
          importUser.organization=organizationId;
          setImportedUser(importUser);
          setIsUserExistModalOpen(true);
          
        } else if (importUser.user == null && importUser.organization == null) {
          //console.log('usuario no existe');
          setstateNewUser(true);
        }

      } else {
        console.log('no valido');
        setError("dni", { message: "RUT inválido" });
      }
      setValidating(false);
    }, 2000);
  };

  const onSubmit = async (data: User) => {
    try {
      data.workingOrganization = organizationId;
      const { payload: userCreated }: any = await dispatch(createUser(data));
      console.log('createform',userCreated);

      if (userCreated != 'Bad Request') {
        history.replace(`/app/administrador/usuarios/${userCreated.id}`);
      }
    } catch (error) { 
      console.log(error);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <IonCard style={{ maxWidth: 700 }}>
        <br />
        <IonItem lines="inset">
          <IonLabel position="stacked">Rut</IonLabel>
          <Controller
            name="dni"
            control={control}
            render={({ field: { onChange, value } }) => (
              <IonInput
                value={value}
                placeholder="Ej. 12.345.678-9"
                type="text"
                {...register("dni", {
                  required: "Campo obligatorio.",
                  validate: {
                    rut: (value) => validate(value),
                  },
                })}
                onIonChange={({ detail: { value } }) => onChange(format(value!))}
                onBlur={(e) => {
                  const value: any = e.target.value;
                  onValidateRut(value);
                }}
              />
            )}
          />
        </IonItem>

        {validating && (
          <IonItem lines="none">
            <IonLabel>Buscando usuario por Rut.</IonLabel>
            <IonSpinner name="lines" color="secondary" />
          </IonItem>
        )}

        {stateNewUser && (
          <IonItem lines="none">
            <IonLabel color="success"><IonIcon icon={checkmarkDoneOutline} /> El usuario no existe en la organización.</IonLabel>
          </IonItem>
        )}

        {errors.dni && (
          <IonItem lines="none">
            <InputErrorMessage errorMessage={errors.dni?.message || "RUT inválido"} />
          </IonItem>
        )}
        
        {isUserExistModalOpen && (
          <ImportUserModal isOpen={isUserExistModalOpen} importedUser={importedUser} />
        )}
        
        <IonItem lines="inset">
          <IonLabel position="stacked">Nombre</IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <IonInput
                placeholder="Ej. John Doe"
                value={value}
                onIonChange={({ detail: { value } }) => onChange(value)}
                {...register("name", {
                  required: "Campo obligatorio.",
                })}
              />
            )}
          />
        </IonItem>
        {errors.name && (
          <IonItem lines="none">
            <InputErrorMessage errorMessage={errors.name?.message || ""} />
          </IonItem>
        )}

        <IonItem lines="inset">
          <IonLabel position="stacked">Email</IonLabel>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <IonInput
                value={value}
                type="email"
                placeholder="Ej. micorreo@gmail.com"
                onIonChange={({ detail: { value } }) => onChange(value)}
                {...register("email", {
                  required: "Campo obligatorio.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Por favor, verifique el email.",
                  },
                })}
              />
            )}
          />
        </IonItem>

        {errors.email && (
          <IonItem lines="none">
            <InputErrorMessage errorMessage={errors.email?.message || ""} />
          </IonItem>
        )}

        <IonItem lines="inset">
          <IonLabel position="stacked">Telefono</IonLabel>
          <Controller
            control={control}
            name="celular"
            render={({ field: { onChange, value } }) => (
              <IonInput
                onIonChange={(e) => onChange(e.detail.value)}
                value={value}
                placeholder="59441191"
                {...register("celular", {
                  required: "Campo obligatorio.",
                })}
              />
            )}
          />
        </IonItem>
        {errors.celular && (
          <InputErrorMessage errorMessage={errors.celular?.message || ""} paddingLeft={15} />
        )}

        <IonItem lines="inset">
          <IonLabel>Estado</IonLabel>
          <Controller
            control={control}
            name="isSignatureEnabled"
            render={({ field: { onChange, value } }) => (

              <IonToggle
                slot="end"
                onIonChange={(e) => onChange(e.detail.checked)}
                checked={value}
              />
            )}
          />
        </IonItem>
        {errors.isSignatureEnabled && (
          <InputErrorMessage errorMessage={errors.isSignatureEnabled?.message || ""} paddingLeft={15} />
        )}

        <br />
        <IonFooter>
          <IonToolbar color="light">
            <IonButtons slot="end">
              <IonButton
                ref={formSubmit}
                fill="solid"
                type="submit"
                disabled={createStatus === "pending"}
              >
                {createStatus === "pending" ? (
                  <>
                    <IonSpinner />
                    <span className="ion-margin-left">Creando usuario</span>
                  </>
                ) : (
                  <>Crear usuario</>
                )}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonCard>
    </form>
  );
};

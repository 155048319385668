import { IonSearchbar } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { resetUsersList, setSearchText } from "../../../store/users";
import findUsers from "../../../store/users/actions/findUsers";
import { selectSearchText } from "../../../store/users/selectors/selectSearchText";

export const UsersSearchBar = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectSearchText);
  const user = useSelector(selectUser);
  const organizationId:any = user?.workingOrganization;
  return (
    <>
      <IonSearchbar
        value={searchText}
        placeholder="Buscar usuario"
        onIonChange=
        {(e) => {
          dispatch(setSearchText(e.detail.value || ""));
          dispatch(resetUsersList())
          dispatch(findUsers(organizationId));
        }}
      />
    </>
  );
};

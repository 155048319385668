import React from "react";
import { IonAvatar } from "@ionic/react";
import { User } from "../@types/user";
import api from "../utils/api";

const UserAvatar: React.FC<{ user?: User }> = ({ user = {} }) => {
  return (
    <IonAvatar slot="start">
      <img
        src={
          user?.profilePicture
            ? `${api.baseURL}file/${user?.profilePicture?.id || user?.profilePicture}`
            : `https://happytravel.viajes/wp-content/uploads/2020/04/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png`
        }
        alt=""
        width="100"
        height="100"
      />
    </IonAvatar>
  );
};

export default UserAvatar;

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Profile } from "../../../@types/profile";

export const createProfile = createAsyncThunk(
  "profile/create",
  async (formData: object, { rejectWithValue }) => {
    try {
      let response = await fetch(`${api.baseURL}profile`, {
        method: "POST",
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw response.statusText;
      }
      let data: Profile = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { createAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../@types/user";
import { findUser } from "./actions/find-user";

interface UsersState {
    user?: User;
    findFetchStatus: string;
}

const initialState = {
    user: {},
    findFetchStatus: "",
} as UsersState;

const userShowSlice = createSlice({
    name: "userShow",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findUser.pending, (state, { payload }) => {
                state.findFetchStatus = "pending";
            })
            .addCase(findUser.fulfilled, (state, { payload }) => {
                state.findFetchStatus = "fulfilled";
                state.user = payload;
            })
            .addCase(findUser.rejected, (state, { payload }) => {
                state.findFetchStatus = "rejected";
            });
    }
})

export default userShowSlice.reducer;
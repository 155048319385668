import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonLabel, IonItem, IonCard } from "@ionic/react";
import api from "../../../utils/api";
import axios from "axios";

export const DashboardIndicators: React.FC = () => {

  const [data, setData] = useState<any>()
    const styles = {
      popCard: {
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        margin: "10px 0",
      },
  
      indicatorCard: {
        flex: 1,
        padding: "10px",
        margin: "5px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)"
      },
  
      indicatorTitle: {
        fontSize: "0.8em",
        color: "#555",
      },
  
      indicatorValue: {
        fontSize: "1.2em",
        fontWeight: "bold",
        textAlign:"center"
      },
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${api.baseURL}dashboard`
          );
          setData(response.data);
        } catch (error) {
          console.error("Failed to fetch data: ", error);
        }
      };
      fetchData();
    }, [])  
    
    const formatDate = (dateString: string) => {
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const formattedDate = new Date(dateString).toLocaleDateString(
        undefined,
        options
      );
      const [month, day, year] = formattedDate.split("/");
      return `${day}/${month}/${year}`;
    };
    
    console.log(data)
    return (
      <IonItem lines="none">
        <IonCard style={styles.popCard} className="ion-no-margin">
          <IonRow>
            <IonCol style={styles.indicatorCard}>
              <IonLabel style={styles.indicatorTitle}>Inscripciones totales</IonLabel>
              <IonLabel style={styles.indicatorValue}>{data?.inscriptions.total_inscriptions ?? "----"}</IonLabel>
            </IonCol>
            <IonCol style={styles.indicatorCard}>
              <IonLabel style={styles.indicatorTitle}>Total acumulado en USD</IonLabel>
              <IonLabel style={styles.indicatorValue}>{data?.inscriptions.total_accumulated_dollar ?? "----"}</IonLabel>
            </IonCol>
            <IonCol style={styles.indicatorCard}>
              <IonLabel style={styles.indicatorTitle}>Total acumulado en pesos</IonLabel>
              <IonLabel style={styles.indicatorValue}>{data?.inscriptions.total_accumulated_peso ?? "----"}</IonLabel>
            </IonCol>
            <IonCol style={styles.indicatorCard}>
              <IonLabel style={styles.indicatorTitle}>Valor Dolar Hoy</IonLabel>
              <IonLabel style={styles.indicatorValue}>{data?.inscriptions.current_dollar_value ?? "----"}</IonLabel>
            </IonCol>
            <IonCol style={styles.indicatorCard}>
              <IonLabel style={styles.indicatorTitle}>Fecha Medición Dolar</IonLabel>
              <IonLabel style={styles.indicatorValue}>{formatDate(data?.inscriptions.current_dollar_date)}</IonLabel>
            </IonCol>
          </IonRow>
        </IonCard>
      </IonItem>
    );
  };
  

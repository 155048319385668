import React from "react";
import { IonAvatar, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import { mailOutline, personOutline } from "ionicons/icons";
import { User } from "../../../@types/user";
import UserAvatar from "../../../components/UserAvatar";

interface ItemListProps {
  user: User;
}

export const UserListItem: React.FC<ItemListProps> = ({ user }) => {
  return (
    <IonItem
      routerLink={`/app/administrador/usuarios/${user.id}`}
      class="ion-margin-bottom"
      lines="none"
      detail
    >
      <UserAvatar user={user} />
      <IonLabel>
        <h2 style={{ fontWeight: 500 }}>{user.name}</h2>
        <h4>
          <IonIcon icon={mailOutline}></IonIcon>
          <IonText class="ion-padding-start">{user.email}</IonText>
        </h4>
      </IonLabel>
    </IonItem>
  );
};

import axios from "axios";
import { RootState } from "./../../rootReducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import { Profile } from "../../../@types/profile";

const findProfile = createAsyncThunk(
  "profileDetail/find",
  async (id: string, { rejectWithValue, getState }) => {
    try {
    //   const state = getState() as RootState;

      const response = await axios.get(`${api.baseURL}profile/${id}`);

      const data: Profile = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default findProfile;

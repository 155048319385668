import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../@types/profile";
import { createProfile } from "./actions/createProfile";
import { updateProfile } from "./actions/updateProfile";

interface ProfileFormState {
  formStatus: string;
  createStatus: string;
  updateStatus: string;
  profile: Profile;
}

const initialState = {
  formStatus: "initial",
  createStatus: "",
  updateStatus: "",
  profile: {},
} as ProfileFormState;

export const resetFormProfile = createAction("RESET_FORM_PROFILE");

const profileFormStateSlice = createSlice({
  name: "profileForm",
  initialState,
  reducers: {
    setFormStatus(state, action: PayloadAction<string>) {
      state.formStatus = action.payload || "";
    },
    setCreateStatus(state, action: PayloadAction<string>) {
      state.createStatus = action.payload || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetFormProfile, (state) => {
        state.formStatus = "initial";
        state.createStatus = "";
        state.updateStatus = "";
        state.profile = {} as Profile;
      })
      .addCase(createProfile.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createProfile.fulfilled, (state, { payload }) => {
        state.createStatus = "fulfilled";
      })
      .addCase(createProfile.rejected, (state) => {
        state.createStatus = "rejected";
      })
      .addCase(updateProfile.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateProfile.rejected, (state) => {
        state.updateStatus = "rejected";
      });
  },
});

export default profileFormStateSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../../@types/user";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";
import axios from "axios";
import { Profile } from "../../../@types/profile";
import { userProfile } from "../../../@types/userProfile";

export const findProfileUsers = createAsyncThunk(
  "profileDetail/findProfileUsers",
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      // const { id } = state.profileDetail?.profile;

      const params = {
        where: {
          profile: id,
        },
      };

      const response = await axios.get(`${api.baseURL}userprofile`, { params });
      const data: userProfile[] = response.data;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

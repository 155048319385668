import axios from "axios";
import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";

export const findDocuments = createAsyncThunk(
  "supervisorHome/findDocuments",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const page = 1;
      const where = {
        or: state.userAuthentication.user?.locations?.map(({ id }) => ({
          locations: { contains: id },
        })),
        status: 2,
      };

      const listPromise = axios.get(`${api.baseURL}document/get-by-locations?locations=[${state.userAuthentication.user?.locations?.map(({ id }) => id)}]`, {
        params: {
          sort: "createdAt DESC",
          limit: 400,
        },
      });
      const countPromise = axios.post(`${api.baseURL}document/count`, {
        where,
      });

      if (page === 1) {
        const [{ data: list }, { data: count }] = await Promise.all([listPromise, countPromise]);

        return [list, count];
      } else {
        const { data: list } = await listPromise;

        return [list];
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

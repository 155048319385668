import { useSelector } from "react-redux";
import { IonCard, IonIcon, IonItem, IonLabel, IonText, IonToggle } from "@ionic/react";
import { selectUser } from "../../../store/userShow/selectors/selectUser";
import { callOutline, cardOutline, eyeOffOutline, eyeOutline, mailOutline } from "ionicons/icons";
export const PersonalDataSection: React.FC = () => {
  const user = useSelector(selectUser);
  const statusUser = user?.isSignatureEnabled ? "Activo" : "Inactivo";
  const checkToogle = user?.isSignatureEnabled ? true : false;

  return (
    <>
      <h2 className="ion-padding-horizontal">
        <IonText color="primary">Datos personales</IonText>
      </h2>
      <IonCard>
        <IonItem lines="none">
          <IonIcon icon={cardOutline} slot="start"></IonIcon>
          <IonLabel>
            <h3>Rut</h3>
            <h3 style={{ fontWeight: 500 }}>{user?.dni}</h3>
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonIcon icon={mailOutline} slot="start"></IonIcon>
          <IonLabel>
            <h3>Email</h3>
            <h3 style={{ fontWeight: 500 }}>{user?.email}</h3>
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonIcon icon={callOutline} slot="start"></IonIcon>
          <IonLabel>
            <h3>Telefono Celular</h3>
            <h3 style={{ fontWeight: 500 }}>{user?.celular}</h3>
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonIcon icon={checkToogle ? eyeOutline : eyeOffOutline} slot="start"></IonIcon>
          <IonLabel>
            <h3>Status</h3>
            <h3 style={{ fontWeight: 500 }}>{statusUser}</h3>
          </IonLabel>
          <IonToggle slot="end" checked={checkToogle} disabled={true}></IonToggle>
        </IonItem>

      </IonCard>
    </>
  );
};

import { createAction, createSlice } from "@reduxjs/toolkit";
import { Message } from "../../@types/message";
import { User } from "../../@types/user";
import { findMessages } from "./actions/findMessages";
import { createUser } from "../userCreate/actions/createUser";
import findUsers from "./actions/findUsers";
interface UsersState {
  users: User[];
  messages: Message[];
  searchText: string;
  page: number;
}

const initialState = {
  users: [],
  messages: [],
  searchText: "",
  page: 0,
} as UsersState;

export const setSearchText = createAction<string>("SET_SEARCH_TEXT_USERS_LIST");
export const incrementPage = createAction("INCREMENT_PAGE");
export const resetUsersList = createAction("RESET_USERS_LIST");

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetUsersList, (state) => {
        state.page = 0;
        state.users = [];
      })
      .addCase(findUsers.fulfilled, (state, { payload }) => {
        if(state.page === 0){
          state.users = payload;
        }else {
          state.users = [...state.users, ...payload];
        }
      })
      .addCase(incrementPage, (state) => {
        state.page += 1;
      })
      .addCase(setSearchText, (state, { payload }) => {
        state.searchText = payload;
      })
      .addCase(findMessages.fulfilled, (state, { payload }) => {
        state.messages = payload;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.users.push(payload);
      });
  },
});

export default usersSlice.reducer;

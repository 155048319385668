import { IonPage, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import React from "react";
import deepEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import Menu from "../layout/Menu";
import { selectAppPages } from "../store/userAuthentication/selectors/selectAppPages";
import { selectIsAuthenticated } from "../store/userAuthentication/selectors/selectIsAuthenticated";
import { CheckRedirect } from "./CheckRedirect";
import { FetchInitialData } from "./FetchInitialData";

export const UserAuthenticatedRouter: React.FC = () => {
  const appPages = useSelector(selectAppPages, deepEqual);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [firstPage] = appPages;

  if (!isAuthenticated) {
    return (
      <>
        <CheckRedirect />
        <Redirect to="/ingresar" />;
      </>
    );
  }

  return (
    <IonPage>
      <IonSplitPane contentId="main" when="(min-width:2000px)">
        <Menu />
        <FetchInitialData />
        <IonRouterOutlet id="main">
          {appPages.map((page, index) => (
            <Route path={page.url} exact={page.exact} key={index}>
              {page.component && <page.component />}
            </Route>
          ))}
          {firstPage ? <Redirect to={firstPage?.url} /> : <IonPage>Redireccionando...</IonPage>}
        </IonRouterOutlet>
      </IonSplitPane>
    </IonPage>
  );
};

import { userProfile } from "./../../@types/userProfile";

import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../@types/profile";
import { User } from "../../@types/user";

import { createProfile } from "../profileForm/actions/createProfile";
import { updateProfile } from "../profileForm/actions/updateProfile";
import { addUserToProfile } from "./actions/addUserToProfile";
import findProfile from "./actions/findProfile";
import { findProfileUsers } from "./actions/findProfileUsers";
import { removeUserFromProfile } from "./actions/removeUserFromProfile";

interface ProfileDetailState {
  profile: Profile;
  selectedSegmentProfile: string;
  findStatus: string;
  userProfileList: userProfile[];
  userList: User[];
  selectedUsers: User[];
  showToastStatus: boolean;
  showToastMessage: string;
  showToastColor: string;
  searchText: string;
  addUserSearchText: string;

  updateStatus: string;
}

const initialState = {
  profile: {},
  selectedSegmentProfile: "general",
  findStatus: "",
  userProfileList: [],
  selectedUsers: [],
  searchText: "",
  addUserSearchText: "",
  userList: [],
  showToastStatus: false,
  showToastMessage: "",

  showToastColor: "",
  updateStatus: "",
} as ProfileDetailState;

export const resetProfileDetail = createAction("RESET_PROFILE_DETAIL");
export const resetShowToastStatus = createAction("RESET_SHOW_TOAST");
export const setSearchText = createAction<string>("SET_USER_PROFILE_SEARCH_TEXT");
const profileDetailSlice = createSlice({
  name: "profileDetail",
  initialState,
  reducers: {
    setSelectedSegmentProfile(state, action: PayloadAction<string>) {
      state.selectedSegmentProfile = action.payload || "";
    },
    setAddUserSearchText(state, { payload }) {
      state.addUserSearchText = payload;
    },
    setPreselectUser(state, { payload }) {
      state.selectedUsers = [...state.selectedUsers, payload];
    },
    setDeselectUser(state, { payload }) {
      state.selectedUsers = state.selectedUsers.filter(({ id }) => id !== payload.id);
    },
    clearSelectedUsers(state) {
      state.selectedUsers = [];
    }
    // setProfileEdit(state, action: PayloadAction<Profile>) {
    //   state.profileEdit = action.payload || {};
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetProfileDetail, (state) => {
        state.profile = {} as Profile;
        state.selectedSegmentProfile = "general";
        state.searchText = "";
        state.addUserSearchText = "";
        state.findStatus = "";
        state.updateStatus = "";
        state.showToastStatus = false;
        state.showToastMessage = "";
        state.showToastColor = "";
        state.userProfileList = [];
      })
      .addCase(resetShowToastStatus, (state) => {
        state.showToastStatus = false;
        state.showToastMessage = "";
      })
      .addCase(setSearchText, (state, { payload }) => {
        state.searchText = payload;
        state.userList = state.userProfileList
          .filter(({ user }) => user && user.name?.includes(payload))
          .map(({ user }) => user);
      })
      .addCase(findProfile.pending, (state) => {
        state.findStatus = "pending";
      })
      .addCase(findProfile.fulfilled, (state, { payload }) => {
        state.findStatus = "fulfilled";
        state.profile = payload;
      })
      .addCase(findProfile.rejected, (state) => {
        state.findStatus = "rejected";
      })

      .addCase(findProfileUsers.fulfilled, (state, { payload }) => {
        // state.findStatus = "fulfilled";
        state.userProfileList = payload;
      })

      .addCase(addUserToProfile.fulfilled, (state, { payload }) => {
        state.userProfileList = [...state.userProfileList, payload];
        state.userList = [...state.userList, payload.user];
        state.showToastStatus = true;
        state.showToastMessage = "Usuario agregado.";
        state.showToastColor = "success";
      })

      .addCase(removeUserFromProfile.fulfilled, (state, { payload }) => {
        state.userProfileList = state.userProfileList.filter(({ id }) => id !== payload.id);
        state.userList = state.userList.filter(({ id }) => id !== payload.user.id);

        state.showToastStatus = true;
        state.showToastMessage = "Usuario eliminado.";
        state.showToastColor = "success";
      })
      .addCase(createProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.showToastStatus = true;
        state.showToastMessage = "Perfil creado correctamente.";
        state.showToastColor = "success";
      })
      .addCase(updateProfile.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.profile = {} as Profile;
        state.profile = payload;
        state.showToastStatus = true;
        state.showToastMessage = "Perfil actualizado correctamente.";
        state.showToastColor = "success";
        state.updateStatus = "fulfilled";
      });
  },
});

export const {
  setAddUserSearchText,
  setPreselectUser,
  setDeselectUser,
  clearSelectedUsers,
} = profileDetailSlice.actions;

export default profileDetailSlice.reducer;

import { IonContent } from "@ionic/react";
import { useSelector } from "react-redux";
import { selectPasswordRecoveryStatus } from "../../../store/userAuthentication/selectors/selectPasswordRecoveryStatus";
import { PasswordRecoveryForm } from "./PasswordRecoveryForm";
import { PasswordRecoveryRequest } from "./PasswordRecoveryRequest";

export const ContentSection = () => {
  const recoveryStatus = useSelector(selectPasswordRecoveryStatus);
  return (
    <IonContent>
      <section style={{ maxWidth: 600 }}>
        {recoveryStatus === "initial" && <PasswordRecoveryRequest />}
        {recoveryStatus === "final" && <PasswordRecoveryForm />}
      </section>
    </IonContent>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import axios from "axios";

export const updateProfilesIdUser = createAsyncThunk(
    "userProfiles/updateProfilesIdUser",
    async (params: any, { getState, rejectWithValue }) => {
        try {
            console.log('params', params);

            const userId = params.idUser;
            const data = params.data;

            const response = axios.post(`${api.baseURL}userprofile/updateiduser`,{
                userId: userId,
                profiles: data
            });

            const dataResponse = await response;

            return dataResponse.data;

        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
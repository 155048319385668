import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../../@types/user";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";
import axios from "axios";
import { Profile } from "../../../@types/profile";
import { userProfile } from "../../../@types/userProfile";

export const addUserToProfile = createAsyncThunk(
  "profileDetail/addUserToProfile",
  async (user: User, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { id: profile } = state.profileDetail.profile;

      const params = {
        user: user.id,
        profile,
      };

      const response = await axios.post(`${api.baseURL}userprofile`, params);
      const data: userProfile = response.data;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

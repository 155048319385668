import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { completeRestorePassword } from "../../../store/userAuthentication/actions/completeRestorePassword";
import { selectError } from "../../../store/userAuthentication/selectors/selectError";
import { FormButtonSubmit } from "./FormButtonSubmit";

export const PasswordRecoveryForm = () => {
  const dispatch = useDispatch();

  const recoveryError = useSelector(selectError);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      token: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await dispatch(completeRestorePassword(data));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }} noValidate>
      <IonItem lines="inset">
        <IonLabel position="stacked">Código de recuperación</IonLabel>
        <Controller
          control={control}
          name="token"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("token", {
                required: "Campo obligatorio.",
                minLength: {
                  value: 6,
                  message: "El código dinámico debe contener exactamente 6 caracteres.",
                },
              })}
              type="text"
              maxlength={6}
              minlength={6}
            />
          )}
        />
      </IonItem>
      {errors.token && (
        <InputErrorMessage errorMessage={errors.token?.message || ""} paddingLeft={15} />
      )}
      <IonItem lines="inset">
        <IonLabel position="stacked">Nueva contraseña</IonLabel>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("password", {
                required: "Campo obligatorio.",
                minLength: {
                  value: 6,
                  message: "La contraseña debe contener entre 4 y 12 caracteres..",
                },
              })}
              type="password"
              maxlength={4}
              minlength={12}
            />
          )}
        />
      </IonItem>
      {errors.password && (
        <InputErrorMessage errorMessage={errors.password?.message || ""} paddingLeft={15} />
      )}

      {recoveryError && (
        <InputErrorMessage
          errorMessage={"Hubo un error con los datos proporcionados. Inténtelo nuevamente." || ""}
          paddingLeft={15}
        />
      )}

      <FormButtonSubmit />
    </form>
  );
};

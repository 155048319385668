import React from "react";
import { IonGrid, IonRow, IonCol, IonSpinner, IonLoading } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ExamsCalendar: React.FC = () => {
  const cellStyle = {
    border: "1px solid #cccccc",
    textAlign: "center",
    margin: 1,
    minWidth: "19%",
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  };

  const allExams = useSelector((state: any) => state.exams?.exams);
  const examsCalendar = useSelector((state: any) => state.exams?.examsCalendar);
  const allCommunes = useSelector((state: any) => state.exams?.communes);
  const status = useSelector((state: any) => state.exams?.status);

  let crossedData = examsCalendar
    .map((calendar: any) => {
      let exam = allExams.find((e: any) => e.id === calendar.id_exam);
      let commune = allCommunes.find((c: any) => c.id === calendar.id_commune);

      if (exam && commune) {
        return {
          ...calendar,
          examName: exam.name,
          examAbbreviation: exam.abbreviation,
          communeName: commune.name,
        };
      } else if (exam) {
        return {
          ...calendar,
          examName: exam.name,
          examAbbreviation: exam.abbreviation,
        };
      } else {
        return calendar;
      }
    })
    .sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // Descending order
      return dateB.getTime() - dateA.getTime();
    });

  // Function to format date
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  return (
    <IonGrid>
      <IonRow style={headerStyle}>
        <IonCol style={headerStyle}>Fecha</IonCol>
        <IonCol style={headerStyle}>Examen</IonCol>
        <IonCol style={headerStyle}>Comuna</IonCol>
        <IonCol style={headerStyle}>Valor</IonCol>
        <IonCol style={headerStyle}>Acciones</IonCol>
      </IonRow>
      {status === "succeeded" ? (
        crossedData.map(
          (
            item: { date: string; examName: any; communeName: any; price: any },
            index: any
          ) => (
            <IonRow key={index}>
              <IonCol style={cellStyle}>{formatDate(item.date)}</IonCol>
              <IonCol style={cellStyle}>{item.examName}</IonCol>
              <IonCol style={cellStyle}>{item.communeName}</IonCol>
              <IonCol style={cellStyle}>$ {item.price}</IonCol>
              <IonCol style={cellStyle}>
                <Link
                  to={{
                    pathname: `/app/calendario-examenes/editar-examen`,
                    state: { item },
                  }}
                >
                  Editar
                </Link>
              </IonCol>
            </IonRow>
          )
        )
      ) : (
        <IonLoading
          isOpen={status === "loading"}
          message={"Cargando Calendario..."}
        />
      )}
    </IonGrid>
  );
};

import { createAction, createSlice } from "@reduxjs/toolkit";
import { Document } from "../../@types/document";
import { Location } from "../../@types/location";
import { Message } from "../../@types/message";
import { UserRole } from "../../@types/userRole";
import { findDocuments } from "./actions/findDocuments";
import { findMessages } from "./actions/findMessages";
import { findUserRoles } from "./actions/findUserRoles";
interface SupervisorHomeState {
  userRoles: UserRole[];
  userRolesFetchStatus: string;
  documents: Document[];
  documentsFetchStatus: string;
  messages: Message[];
  locationSearchText: string;
  userRolesSearchText: string;
  documentSearchText: string;
}

const initialState = {
  userRoles: [],
  userRolesFetchStatus: "",
  documents: [],
  documentsFetchStatus: "",
  messages: [],
  locationSearchText: "",
  userRolesSearchText: "",
  documentSearchText: "",
} as SupervisorHomeState;

export const setLocationSearchText = createAction<string>("setLocationSearchText");
export const setUserRolesSearchText = createAction<string>("setUserRolesSearchText");
export const setDocumentSearchText = createAction<string>("setDocumentSearchText");

const supervisorHomeSlice = createSlice({
  name: "supervisorHome",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setDocumentSearchText, (state, { payload }) => {
        state.documentSearchText = payload;
      })
      .addCase(setLocationSearchText, (state, { payload }) => {
        state.locationSearchText = payload;
      })
      .addCase(setUserRolesSearchText, (state, { payload }) => {
        state.userRolesSearchText = payload;
      })
      // findUserRoles
      .addCase(findUserRoles.pending, (state, { payload }) => {
        state.userRoles = [];
        state.userRolesFetchStatus = "pending";
      })
      .addCase(findUserRoles.fulfilled, (state, { payload }) => {
        state.userRoles = payload;
        state.userRolesFetchStatus = "fulfilled";
      })
      // findDocuments
      .addCase(findDocuments.pending, (state) => {
        state.documents = [];
        state.documentsFetchStatus = "pending";
      })
      .addCase(findDocuments.fulfilled, (state, { payload }) => {
        const [documents] = payload;
        state.documents.push(...documents);
        state.documentsFetchStatus = "fulfilled";
      })
      // findMessages
      .addCase(findMessages.pending, (state, { payload }) => {
        state.messages = [];
      })
      .addCase(findMessages.fulfilled, (state, { payload }) => {
        state.messages = payload;
      });
  },
});

export const supervisorHome = supervisorHomeSlice.reducer;

import {
  IonList,
  IonListHeader,
  IonButton,
  IonIcon,
  IonText,
  IonContent,
  IonPopover,
  IonItem,
} from "@ionic/react";
import { cloudCircleOutline, helpBuoyOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectAppPages } from "../../../store/userAuthentication/selectors/selectAppPages";
import { RestorePasswordBtn } from "./RestorePasswordBtn";

export const HelperActions: React.FC = () => {
  const { id }: { id: string } = useParams();

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const appPages = useSelector(selectAppPages);

  const adminPages = appPages.filter(({ title }) => title.includes("Usuarios") || title.includes("Locaciones") || title.includes("Perfiles") || title.includes("Organizaciones"));

  return (
    <>
      <IonButton
        id="size-button"
        onClick={(e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
      >
        <IonIcon icon={helpBuoyOutline} slot="start"></IonIcon>
        <IonText>Acciones</IonText>
      </IonButton>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        style={{ '--offset-y': '0px' }}
      >
        <IonContent>
          <IonList>
            <IonListHeader>
              <IonText>Acciones</IonText>
            </IonListHeader>
            <IonItem
              routerLink={`/app/administrador/usuarios/${id}/editar`}
              lines="none"
              onClick={() => {
                setShowPopover({ showPopover: false, event: undefined });
              }}
            >
              <IonIcon icon={pencilOutline} />
              <IonText class="ion-padding-start">Editar Usuario</IonText>
            </IonItem>
            <RestorePasswordBtn></RestorePasswordBtn>
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, isPlatform, IonTitle, IonContent } from "@ionic/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { findUser } from "../../store/userShow/actions/find-user";
import { HeaderSection } from "./components/HeaderSection";
import { HelperActions } from "./components/HelperActions";
import { PersonalDataSection } from "./components/PersonalDataSection";

export const UserShow: React.FC = () => {
    const dispatch = useDispatch();
    const { id }: { id: string } = useParams();
    useEffect(() => {
        const findData = async () => {
            await dispatch(findUser(Number(id)));
        };
        findData();
    }, [dispatch, id]);

    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text={isPlatform("ios") ? "Usuarios" : ""}
                            defaultHref="/app/administrador/usuarios"
                            color="light"
                        />
                    </IonButtons>
                    <IonTitle>Usuario</IonTitle>
                    <IonButtons slot="end">
                        <HelperActions />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense" class="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">Usuario</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <HeaderSection />
                <PersonalDataSection />
            </IonContent>
        </IonPage>
    )
}
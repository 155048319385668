import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonFab, IonButton, IonIcon, IonText, IonGrid, IonRow, IonCol, IonMenuButton } from "@ionic/react"
import { addOutline } from "ionicons/icons"
import { UsersSearchBar } from "./components/UsersSearchBar"
import findUsers from "../../store/users/actions/findUsers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { UsersListContainer } from "./components/UsersListContainer";
import { selectUser } from "../../store/userAuthentication/selectors/selectUser";
import { resetUsersList } from "../../store/users";

export const UsersList: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const organizationId:any = user?.workingOrganization;
    //console.log(organizationId);
    useEffect(() => {
        dispatch(resetUsersList())
        dispatch(findUsers(organizationId));
    }, [dispatch]);
    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton color="light" />
                    </IonButtons>
                    <IonTitle>Usuarios</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense" class="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">Usuarios</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonButton routerLink="/app/administrador/usuarios/nuevo" size="large" color="secondary">
                        <IonIcon icon={addOutline} slot="start"></IonIcon>
                        <IonText>Crear usuario</IonText>
                    </IonButton>
                </IonFab>
                <section className="ion-padding">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeMd="8" sizeLg="6">
                                <UsersSearchBar />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <UsersListContainer />
                </section>
            </IonContent>
        </IonPage>
    )
}
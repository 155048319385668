import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonItem,
  IonLabel,
  IonButton,
  IonFooter,
  IonModal,
  IonHeader,
  IonToolbar,
  IonToast,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { postInscriptionData } from "../../../store/inscriptions/actions/postData";
import { setInitState } from "../../../store/inscriptions";

interface PaymentSelectionProps {
  forPost?: any;
}
export const PaymentSelection: React.FC<PaymentSelectionProps> = ({
  forPost,
}) => {
  const dispatch: any = useDispatch();
  const postStatus = useSelector((state: any) => state.inscriptions.postStatus);
  const [dataToDispatch, setDataToDispatch] = useState(forPost);

  const [showPresencial, setShowPresencial] = useState(false);
  const [showTransferencia, setShowTransferencia] = useState(false);

  const handleSendOrder = async (method: string) => {
    setShowPresencial(false);
    setShowTransferencia(false);
    try {
      const updatedData = {
        ...dataToDispatch,
        payment: {
          ...dataToDispatch.payment,
          method: method,
        },
      };

      // Dispatch the updated data
      const result = await dispatch(postInscriptionData(updatedData));
      unwrapResult(result);

      // Now, update the state with the new data
      setDataToDispatch(updatedData);
      unwrapResult(result);
    } catch (err: any) {

    } finally {
    }
  };
  return (
    <>
      <IonGrid>
        <IonRow style={{ marginTop: 40, marginBottom: 20 }}>
          <IonCol>
            <IonTitle>Método de Pago</IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton>Webpay</IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={() => setShowTransferencia(true)}>
              Transferencia
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={() => setShowPresencial(true)}>
              Pago Presencial
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal
        isOpen={showTransferencia}
        onDidDismiss={() => setShowTransferencia(false)}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Pago por Transferencia Bancaria</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Enim,
                hic!
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ justifyContent: "center", display: "flex" }}>
            <IonButton onClick={() => handleSendOrder("transferencia")}>
              Enviar Orden
            </IonButton>
          </IonCol>
        </IonRow>
        <IonFooter
          style={{ height: "2rem" }}
          className="ion-no-border"
        ></IonFooter>
      </IonModal>
      <IonModal
        isOpen={showPresencial}
        onDidDismiss={() => setShowPresencial(false)}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Pago Presencial</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Enim,
                hic!
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ justifyContent: "center", display: "flex" }}>
            <IonButton onClick={() => handleSendOrder("presencial")}>
              Enviar Orden
            </IonButton>
          </IonCol>
        </IonRow>
        <IonFooter
          style={{ height: "2rem" }}
          className="ion-no-border"
        ></IonFooter>
      </IonModal>
      <IonToast
        isOpen={postStatus === "loading"}
        onDidDismiss={() => dispatch(setInitState())}
        message="Enviando Orden"
        duration={2000}
        color="primary"
      />
      {/* Success Toast */}
      <IonToast
        isOpen={postStatus === "success"}
        message="Orden Enviada"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="success"
      />

      {/* Error Toast */}
      <IonToast
        isOpen={postStatus === "failed"}
        message="A ocurrido un error"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="danger"
      />
    </>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postInscriptionData = createAsyncThunk(
  "inscriptions/postInscriptionData",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api.baseURL}inscriptions`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

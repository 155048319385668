import React from 'react';
import { Pie } from '@ant-design/charts';

// Define the shape of the PieChartData
interface PieChartData {
  type: string;
  value: number;
  color?: string;
}

interface PieChartProps {
  data: PieChartData[];
}

export const PieChart: React.FC<PieChartProps> = ({ data }) => {
  // Configure the Pie Chart
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    color: ({ type }: { type: string }) => {
      const item = data.find(d => d.type === type);
      return item?.color || '';  // Return the color from props or fallback to default
    },
    label: {
      type: 'inner',
      content: '{value}',
      style: {
        fill: '#fff',
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    interactions: [],
    legend: {
      position: 'bottom',
      itemName: {
        formatter: (text: any) => text,
      },
      marker: {
        symbol: 'circle',
      },
    },
  };

  return <Pie {...config} />;
};

import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { appPages } from "../../../utils/appPages";
import { Profile } from "../../../@types/profile";

export const selectAppPages = createSelector(
  [(store: RootState) => store.userAuthentication.user],
  (user) => {
    if (!user) {
      return [];
    }

    const privileges: string[] =
      user?.profiles?.reduce(
        (privileges: string[], profile: Profile) => [...privileges, ...(profile.privileges || [])],
        []
      ) || [];

    const filteredPages = appPages.filter(({ title, restricted }) =>
      restricted ? privileges.includes(title) : true
    );

    return filteredPages;
  }
);

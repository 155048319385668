import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getInscriptions } from "./getInscriptions";

export const modifyInscription = createAsyncThunk(
    "inscriptions/modifyInscription",
    async (data : any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.patch(`${api.baseURL}payments`, data);
            dispatch(getInscriptions())
            return response.data;
        } catch (error : any) {
            return rejectWithValue(error.response.data);
        }
    }
)

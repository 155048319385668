import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setRedirect } from "../store/userAuthentication/userAuthentication.reducer";

export const CheckRedirect: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setRedirect(pathname));
  }, [pathname, dispatch]);

  return <></>;
};

import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonDatetime,
  IonInput,
  IonItem,
  IonGrid,
  IonButton,
  IonIcon,
  IonText,
  IonToast,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { addOutline } from "ionicons/icons";
import { createExam } from "../../../store/exams/actions/createExam";
import { setInitState } from "../../../store/exams";

export const AddNewExam: React.FC = () => {
  const dispatch: any = useDispatch();
  const [selectedExam, setSelectedExam] = useState<any>("");
  const [selectedRegion, setSelectedRegion] = useState<any>("");
  const [selectedCommune, setSelectedCommune] = useState<any>("");
  const [examDate, setExamDate] = useState<any>(null);
  const [examValue, setExamValue] = useState<any>("");
  const [examAbb, setExamAbb] = useState<any>("");
  const [error, setError] = useState<any>("");

  const createExamStatus = useSelector(
    (state: any) => state.exams.createExamStatus
  );

  const validateForm = () => {
    if (!selectedExam) {
      setError("Por favor ingrese un nombre para el examen");
      return false;
    }

    if (!examAbb) {
      setError("Por favor ingrese una abreviación");
      return false;
    }

    if (!examValue) {
      setError("Por favor ingrese un valor");
      return false;
    }

    return true;
  };

  useEffect(() => {
    dispatch(setInitState());
  }, []);

  const handleAddExam = () => {
    if (validateForm()) {
      dispatch(
        createExam({
          name: selectedExam,
          abbreviation: examAbb,
          dollarPrice: examValue,
        })
      );
    }
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/examenes" color="light" />
          </IonButtons>
          <IonTitle>Añadir Nuevo Examen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="8" sizeLg="6">
                <IonTitle style={{ paddingInline: 0 }}>
                  Añadir Nuevo Examen
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div style={{}}>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Examen:</IonLabel>
                  <IonInput
                    value={selectedExam}
                    placeholder="Nombre del examen"
                    onIonChange={(e: any) => setSelectedExam(e.detail.value)}
                    type="text"
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Abreviación:</IonLabel>
                  <IonInput
                    value={examAbb}
                    placeholder="Ingrese Abreviación"
                    onIonChange={(e: any) => setExamAbb(e.detail.value)}
                    type="text"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonItem>
                  <IonLabel>Valor: $</IonLabel>
                  <IonInput
                    value={examValue}
                    placeholder="Ingrese Valor en dolares"
                    onIonChange={(e: any) => setExamValue(e.detail.value)}
                    type="number"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 20,
              }}
            >
              <IonCol size="3">
                {error && <IonLabel color="danger">{error}</IonLabel>}
                <IonButton onClick={handleAddExam}>
                  <IonIcon icon={addOutline} slot="start"></IonIcon>
                  <IonText>Añadir Examen</IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </section>
      </IonContent>
      <IonToast
        isOpen={createExamStatus === "loading"}
        message="Creando Examen..."
        position="bottom"
        duration={6000}
        color="primary"
      />

      {/* Success Toast */}
      <IonToast
        isOpen={createExamStatus === "succeeded"}
        message="Examen Creado"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="success"
      />

      {/* Error Toast */}
      <IonToast
        isOpen={createExamStatus === "failed"}
        message="A ocurrido un error"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="danger"
      />
    </IonPage>
  );
};

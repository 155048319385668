import { IonToast } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateStatus } from "../../../store/userProfiles";
import { selectUpdateStatus } from "../../../store/userProfiles/selectors/selectUpdateStatus";

export const SuccessToast = () => {
    const dispatch = useDispatch();
    const UpdateStatus = useSelector(selectUpdateStatus);
    return(
        <IonToast
            isOpen={UpdateStatus === "fulfilled"}
            onDidDismiss={() => dispatch(setUpdateStatus())}
            message="El usuario se actualizo correctamente."
            color="success"
            duration={4000}
            position="top"
        />
    )
}
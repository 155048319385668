import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonImg,
  IonLoading,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonToast,
} from "@ionic/react";

import { mailOutline, shieldOutline } from "ionicons/icons";
import login from "../../../store/userAuthentication/actions/login";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { selectLoginStatus } from "../../../store/userAuthentication/selectors/selectLoginStatus";
import { initState } from "../../../store/userAuthentication/userAuthentication.reducer";

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();

  const imgStyle = {
    width: "300px",
    height: "300px",
    margin: "32px auto",
  };

  const loginStatus = useSelector(selectLoginStatus);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await dispatch(login(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(initState());
  }, [dispatch]);


  return (
    <IonContent>
      <IonRow style={{ height: "100%" }}>
        <IonCol size="0" size-sm="7" style={{ padding: 0 }}>
          <img
            src="assets/login_bg.png"
            alt=""
            style={{ height: "100%", objectFit: "cover" }}
          />
        </IonCol>
        <IonCol size="12" size-sm="5" style={{ padding: 0 }}>
          <IonImg src="assets/gs-logo.png" style={imgStyle} />
          <IonText class="ion-text-center" color="primary">
            <h1>
              <strong>Sistema de Administración</strong>
            </h1>
          </IonText>
          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }} noValidate>
            <IonItem lines="inset">
              <IonIcon slot="start" icon={mailOutline} class="ion-align-self-end"></IonIcon>
              <IonLabel position="stacked">Email</IonLabel>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <IonInput
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                    {...register("email", {
                      required: "Campo obligatorio.",
                    })}
                  ></IonInput>
                )}
              />
            </IonItem>
            <InputErrorMessage errorMessage={errors.email?.message || ""} paddingLeft={67} />

            <IonItem lines="inset">
              <IonIcon slot="start" icon={shieldOutline} class="ion-align-self-end"></IonIcon>
              <IonLabel position="stacked">Contraseña</IonLabel>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <IonInput
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                    {...register("password", {
                      required: "Campo obligatorio.",
                    })}
                    type="password"
                  ></IonInput>
                )}
              />
            </IonItem>
            <InputErrorMessage errorMessage={errors.password?.message || ""} paddingLeft={67} />

            <IonButton type="submit" expand="block" style={{ marginTop: 40 }}>
              Ingresar
            </IonButton>
          </form>

          <br />

          <IonButton
            routerLink="/recuperar"
            size="small"
            color="tertiary"
            expand="block"
            class="ion-margin-top"
            fill="clear"
          >
            Recuperar contraseña
          </IonButton>
        </IonCol>
      </IonRow>
      <IonLoading isOpen={loginStatus === "pending"} message={"Ingresando..."} />
      <IonToast
        isOpen={loginStatus === "rejected"}
        message="Ingreso fallido"
        position="top"
        onWillDismiss={() =>
          dispatch({
            type: "userAuthentication/setLoginStatus",
            payload: "initial",
          })
        }
        duration={6000}
        color="danger"
      />
    </IonContent>
  );
};

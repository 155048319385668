import React, {useEffect} from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { getExams } from "../../store/exams/actions/getExams";
import { ExamsList } from "./components/ExamsList";

export const ExamsMain: React.FC = () => {

    const dispatch : any = useDispatch()

    useEffect(() => {
        dispatch(getExams())
    }, [])
    
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="light" />
          </IonButtons>
          <IonTitle>Exámenes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Exámenes</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonButton routerLink="/app/examenes/anadir-examen" size="large" color="secondary">
            <IonIcon icon={addOutline} slot="start"></IonIcon>
            <IonText>Añadir Examen</IonText>
          </IonButton>
        </IonFab>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="8" sizeLg="6">
                <IonTitle style={{paddingInline:0}}>
                    Exámenes
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
          <ExamsList/>
          <div style={{marginTop:60}}></div>
        </section>
      </IonContent>
    </IonPage>
  );
};

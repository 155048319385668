import React from 'react'
import { Column } from '@ant-design/charts';

interface BarChartData {
    type: string;
    value: number;
    color?: string;
  }
  
  interface BarChartProps {
    data: BarChartData[];
  }

export const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const config = {
      data,
      xField: 'type',
      yField: 'value',
      color: ({ type }: { type: string }) => {
        const item = data.find(d => d.type === type);
        return item?.color || ''; // Return the color from props or fallback to default
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      yAxis: {
        label: {
          formatter: (v: string) => `${v}`,
        },
      },
      legend: {
        position: 'bottom',
      },
    };
  
    return <Column {...config} />;
  };
  
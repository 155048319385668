import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../../@types/user";
import axios from "axios";
import { RootState } from "../../rootReducer";

const findUsers = createAsyncThunk("users/find", async (organizationId:Number, { rejectWithValue, getState }) => {
  //console.log("findUsers.ts: searchText: ", organizationId)
  const limit = 10;
  try {
    
    const state = getState() as RootState;

    const searchText = state.users.searchText;

    const page = state.users.page;

    const skip = limit * page;

    //console.log('limit', limit, ' searchtext', searchText, ' skip', skip, ' page', page);

    const listUser = axios.get(`${api.baseURL}userOrganization/users/${organizationId}`,{
      params: {
        limit: limit,
        search: searchText,
        skip: skip,
        sort: 'createdAt ASC'
      },
    });

    const data = await listUser;

    return data.data;
    
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default findUsers;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";

export const requestRecoveryToken = createAsyncThunk(
  "userAuthentication/requestRecoveryToken",
  async (email: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      const response = await axios.post(`${api.baseURL}user/request-restore-password`, {
        email,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { PostulantData } from "./components/Tab1";
import { ExamSelection } from "./components/Tab2";
import { ConfirmPayment } from "./components/Tab3";
import { getExams } from "../../store/exams/actions/getExams";
import { getCommunes } from "../../store/exams/actions/getCommunes";
import { getRegions } from "../../store/exams/actions/getRegions";
import { setNextStep, showErrors } from "../../store/userInscriptions";
import { getExamsCalendar } from "../../store/exams/actions/getExamsCalendar";
import { PaymentSelection } from "./components/Tab4";

export const UserInscription: React.FC = () => {
  const dispatch: any = useDispatch();
  const formValidation = useSelector(
    (state: any) => state.userInscription.formValidation
  );

  useEffect(() => {
    dispatch(getExams());
    dispatch(getCommunes());
    dispatch(getRegions());
    dispatch(getExamsCalendar());
    dispatch(showErrors(false));
  }, [dispatch]);

  //States for first step
  const [names, setSelectedNames] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<any>("");
  const [rut, setRut] = useState<any>("");
  const [gender, setGender] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<any>("");
  const [selectedCommune, setSelectedCommune] = useState<any>("");
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  //States for second step

  const [selectedCommune2, setSelectedCommune2] = useState();
  const [selectedExamCalendars, setSelectedExamCalendars] = useState([]);
  const [selectedExams, setSelectedExams] = useState<(string | number)[]>([]);

  //States for third step

  const [checkSamePersonPays, setCheckSamePersonPays] =
    useState<boolean>(false);
  const [step3Region, setStep3Region] = useState<any>();
  const [step3Commune, setStep3Commune] = useState<any>();
  const [step3Rut, setStep3Rut] = useState<any>("");
  const [step3Name, setStep3Name] = useState<string>("");
  const [step3Email, setStep3Email] = useState<string>("");
  const [step3DiscountCode, setStep3DiscountCode] = useState<string>("")
  const [step3TotalAmount, setStep3TotalAmount] = useState<string>("")
  const [step3DiscountAmount, step3SetDiscountAmount] = useState(0);

  const dataToPost= {
    inscription:{
      name:names,
      last_name:lastName,
      birthdate:birthDate,
      dni:rut,
      adress:address,
      phone:phone,
      email:email,
      gender:gender,
      region:selectedRegion,
      commune:selectedCommune
    },
    calendar:{
      id_calendar:selectedExams
    },
    payment:{
      total:step3TotalAmount,
      discount_code:step3DiscountCode
    },
    payment_bill:{
      name:checkSamePersonPays ? names : step3Name,
      dni: checkSamePersonPays ? rut : step3Rut,
      email:checkSamePersonPays ? email : step3Email,
      region: checkSamePersonPays ? selectedRegion : step3Region,
      commune: checkSamePersonPays ? selectedCommune : step3Commune
    }
  }


  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    <PostulantData
      names={names}
      lastName={lastName}
      birthDate={birthDate}
      rut={rut}
      gender={gender}
      selectedRegion={selectedRegion}
      selectedCommune={selectedCommune}
      address={address}
      phone={phone}
      email={email}
      setSelectedNames={setSelectedNames}
      setLastName={setLastName}
      setBirthDate={setBirthDate}
      setRut={setRut}
      setGender={setGender}
      setSelectedRegion={setSelectedRegion}
      setSelectedCommune={setSelectedCommune}
      setAddress={setAddress}
      setPhone={setPhone}
      setEmail={setEmail}
    />,
    <ExamSelection
      selectedCommune={selectedCommune2}
      setSelectedCommune={setSelectedCommune2}
      selectedExamCalendars={selectedExamCalendars}
      setSelectedExamCalendars={setSelectedExamCalendars}
      selectedExams={selectedExams}
      setSelectedExams={setSelectedExams}
    />,
    <ConfirmPayment
      check={checkSamePersonPays}
      setCheck={setCheckSamePersonPays}
      selectedRegion={step3Region}
      setSelectedRegion={setStep3Region}
      selectedCommune={step3Commune}
      setSelectedCommune={setStep3Commune}
      rut={step3Rut}
      setRut={setStep3Rut}
      name={step3Name}
      setName={setStep3Name}
      email={step3Email}
      setEmail={setStep3Email}
      discount={step3DiscountCode}
      setDiscount={setStep3DiscountCode}
      totalAmount1={step3TotalAmount}
      setTotalAmount={setStep3TotalAmount}
      discountAmount={step3DiscountAmount}
      setDiscountAmount={step3SetDiscountAmount}
    />,
    <PaymentSelection forPost={dataToPost}/>,
  ];

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol style={{ textAlign: "center", marginBottom: 20 }}>
              <IonTitle>SISTEMA DE INSCRIPCIONES</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSegment value={activeTab.toString()} mode="ios">
                {tabs.map((tab, index) => (
                  <IonSegmentButton
                    key={index}
                    value={index.toString()}
                    disabled={true}
                  >
                    <IonLabel>
                      {index === 0
                        ? "Datos Postulante"
                        : index === 1
                        ? "Exámenes"
                        : index === 2
                        ? "Confirmación de Compra"
                        : index === 3
                        ? "Pago"
                        : null}
                    </IonLabel>
                  </IonSegmentButton>
                ))}
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{tabs[activeTab]}</IonCol>
          </IonRow>
          <IonRow style={{ marginTop: 40 }}>
            <IonCol style={{ justifyContent: "center", display: "flex" }}>
              <IonButton
                disabled={activeTab === 0}
                onClick={() => setActiveTab(activeTab - 1)}
              >
                Anterior
              </IonButton>
              <IonButton
                disabled={activeTab === tabs.length - 1 || activeTab === 4}
                onClick={() => {
                  if (formValidation) {
                    dispatch(showErrors(false));
                    dispatch(setNextStep(true));
                    setActiveTab(activeTab + 1);
                  } else {
                    dispatch(showErrors(true));
                  }
                }}
              >
                Siguiente
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

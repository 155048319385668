import { useDispatch, useSelector } from "react-redux";
import { IonIcon, IonItem, IonText, useIonAlert, useIonToast } from "@ionic/react";
import { selectUser } from "../../../store/userShow/selectors/selectUser";
import { resetPassword } from "../../../store/userShow/actions/reset-password";
import { cloudCircleOutline, shieldHalfOutline } from "ionicons/icons";
export const RestorePasswordBtn: React.FC = () => {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const onConfirm = async () => {
    try {
      await dispatch(resetPassword());
      presentToast({
        message: "Se restauró la contraseña correctamente",
        duration: 800,
      });
    } catch (error) {
      presentToast({
        message: "Ocurrió un problema restaurando contraseña del usuario",
        duration: 800,
      });
    }
  };
  return (
    <>
      <IonItem
        button
        onClick={() =>
          presentAlert({
            header: "Alerta",
            message: `¿Está seguro de restaurar la contraseña del usuario ${user?.name} a los 4 primeros dígitos del rut?`,
            buttons: ["Cancelar", { text: "Restaurar contraseña", handler: onConfirm }],
          })
        }
        lines="none"
      >
        <IonIcon icon={shieldHalfOutline} />
        <IonText class="ion-padding-start">Restaurar contraseña</IonText>
      </IonItem>
    </>
  );
};

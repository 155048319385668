import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  IonGrid,
  IonButton,
  IonText,
  IonCard,
  IonToast,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import api from "../../../utils/api";
import axios from "axios";
import { setInitState } from "../../../store/inscriptions";
import { useSelector, useDispatch } from "react-redux";
import { modifyInscription } from "../../../store/inscriptions/actions/modifyInscription";

export const EditInscription: React.FC = () => {
  const { id }: { id: string } = useParams();
  const dispatch: any = useDispatch();

  const [paymentState, setPaymentState] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const modifyExamStatus = useSelector(
    (state: any) => state.inscriptions.modifyInscriptionStatus
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${api.baseURL}inscriptions?id_inscription=${id}`
        );
        setData(response.data);
        setPaymentState(response.data.payment.isPaid ? 1 : 0);
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setPaymentState(data.payment);
  }, []);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    dispatch(setInitState());
  }, []);

  const handleEditInscription = () => {
    dispatch(
      modifyInscription({
        id: data.payment.id,
        method: data.payment.method,
        isPaid: paymentState === 0 ? false : true,
        total: data.payment.total,
        id_discount: data.payment.discount?.id,
        id_inscription: data.inscription.id,
      })
    );
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/inscripciones" color="light" />
          </IonButtons>
          <IonTitle>
            {data
              ? `Ficha De Inscripción: ${data.inscription?.name} ${data.inscription?.last_name}`
              : "Cargando..."}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section className="ion-padding">
          <IonGrid style={{ marginTop: 20 }}>
            <IonRow>
              <IonCol size="6">
                <IonCard
                  style={{
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      color: "black",
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Información Personal
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Nombre:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.name ?? "No hay registro de Nombre"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Apellido:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.last_name ?? ""}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Fecha de Nacimiento:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {formatDate(data.inscription?.birthdate) ?? "--/--/--"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    RUT:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.dni ?? "No hay registro de RUT"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Dirección:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.adress ??
                        "No hay registro de Dirección"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Teléfono:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.phone ?? "No hay registro de Teléfono"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Email:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.inscription?.email ?? "No hay registro deEmail"}
                    </IonText>
                  </IonLabel>
                </IonCard>
                <IonCard
                  style={{
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      color: "black",
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Examen Inscrito
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Examen(es):
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.calendar && data.calendar.length > 0
                        ? Array.from(
                            new Set(
                              data.calendar.flatMap(
                                (calendar: any) =>
                                  `${calendar.exam?.abbreviation}`
                              )
                            )
                          ).join(", ")
                        : "Sin Exámenes Inscritos"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Fecha(s) de Examen(es):
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.calendar && data.calendar.length > 0
                        ? Array.from(
                            new Set(
                              data.calendar.flatMap(
                                (calendar: any) =>
                                  `${formatDate(calendar.date)} (${
                                    calendar.exam?.abbreviation
                                  })`
                              )
                            )
                          ).join(", ")
                        : "Sin Exámenes Inscritos"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Comuna(s) de Rendición:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.calendar && data.calendar.length > 0
                        ? Array.from(
                            new Set(
                              data.calendar.flatMap(
                                (calendar: any) =>
                                  `${calendar.commune} (${calendar.exam?.abbreviation})`
                              )
                            )
                          ).join(", ")
                        : "Sin Exámenes Inscritos"}
                    </IonText>
                  </IonLabel>
                </IonCard>
              </IonCol>
              <IonCol size="6">
                <IonCard
                  style={{
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      color: "black",
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Estado de Pago
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Valor Examen(es):
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.payment?.total ?? "-----"}
                    </IonText>
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      margin: "10px 0",
                    }}
                  >
                    Codigo de Descuento:
                    <IonText
                      style={{
                        fontWeight: "200",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {" "}
                      {data.payment?.discount ? data.payment.discount?.value : " ----"}
                    </IonText>
                  </IonLabel>
                  <IonItem
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "50%",
                    }}
                    lines="none"
                  >
                    <IonLabel>Estado de Pago: </IonLabel>
                    <IonSelect
                      value={paymentState}
                      placeholder="Estado de pago"
                      onIonChange={(e: any) => setPaymentState(e.detail.value)}
                    >
                      <IonSelectOption key={0} value={0}>
                        Pendiente
                      </IonSelectOption>
                      <IonSelectOption key={1} value={1}>
                        Pagado
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCard>
                <IonCard
                  style={{
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      color: "black",
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Código de Inscripción
                  </IonLabel>
                  <IonLabel
                    style={{
                      fontWeight: "500",
                      color: "black",
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                  >
                    {" "}
                    {data && data.inscription
                      ? data.inscription?.code
                      : "------------"}
                  </IonLabel>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 20,
              }}
            >
              <IonCol size="3">
                <IonButton onClick={handleEditInscription}>
                  <IonText>Actualizar</IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
      </IonContent>
      <IonToast
        isOpen={modifyExamStatus === "loading"}
        message="Modificando Estado de Pago..."
        position="bottom"
        duration={6000}
        color="primary"
      />

      {/* Success Toast */}
      <IonToast
        isOpen={modifyExamStatus === "succeeded"}
        message="Estado de Pago Modificado"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="success"
      />

      {/* Error Toast */}
      <IonToast
        isOpen={modifyExamStatus === "failed"}
        message="A ocurrido un error"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="danger"
      />
    </IonPage>
  );
};

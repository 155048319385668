import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { UserCreateForm } from "./components/UserCreateForm";
import { SuccessToast } from "./components/SuccessToast";


export const UserCreate: React.FC = () => {
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text={isPlatform("ios") ? "Usuarios" : ""}
              defaultHref="/app/administrador/usuarios"
              color="light"
            />
          </IonButtons>
          <IonTitle>Nuevo usuario</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Nuevo usuario</IonTitle>
          </IonToolbar>
        </IonHeader>
        <UserCreateForm />
        <SuccessToast />
      </IonContent>
    </IonPage>
  );
};

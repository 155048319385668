import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import axios from "axios";

export const findUserProfiles = createAsyncThunk(
    "userProfiles/findUserProfiles",
    async (params: any, { getState, rejectWithValue }) => {
        try {
            
            const userId = params.userId;
            const organizationid =  params.organizationId;
            const isSuperUser = params.isSuperUser;

            const response = axios.post(`${api.baseURL}userProfile/findUserProfiles`,{
                userId: userId,
                organizationid: organizationid,
                isSuperUser: isSuperUser
            });

            const data = await response;

            return data.data;

        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
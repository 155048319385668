import { IonInput, IonItem, IonLabel, IonToggle, useIonLoading } from "@ionic/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { updateUser } from "../../../store/userShow/actions/update-user";
import { selectUser } from "../../../store/userShow/selectors/selectUser";
import { FormButtonSubmit } from "../../Profile/components/FormButtonSubmit";
import { validate, format } from "rut.js";

export const UpdateUserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const [presentLoading, dismissLoading] = useIonLoading();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      dni: user?.dni,
      celular: user?.celular,
      isSignatureEnabled: user?.isSignatureEnabled,
    },
  });

  useEffect(() => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("dni", user?.dni);
    setValue("celular", user?.celular);
    setValue("isSignatureEnabled", user?.isSignatureEnabled);
  }, [user, setValue]);

  const onSubmit = async (data: any) => {
    try {
      presentLoading({ message: "Actualizando..." });
      await dispatch(updateUser(data));
      dismissLoading();
      history.replace(`/app/administrador/usuarios/${user?.id}`);
    } catch (error) {
      dismissLoading();
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }} noValidate>
      <IonItem lines="inset">
        <IonLabel position="stacked">Nombre</IonLabel>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("name", {
                required: "Campo obligatorio.",
              })}
            />
          )}
        />
      </IonItem>
      {errors.name && (
        <InputErrorMessage errorMessage={errors.name?.message || ""} paddingLeft={15} />
      )}

      <IonItem lines="inset">
        <IonLabel position="stacked">Rut</IonLabel>
        <Controller
          name="dni"
          control={control}
          render={({ field: { onChange, value } }) => (
            <IonInput
              value={value}
              placeholder="Ej. 12.345.678-9"
              type="text"
              {...register("dni", {
                required: "Campo obligatorio.",
                validate: {
                  rut: (value) => validate(value || ""),
                },
              })}
              onIonChange={({ detail: { value } }) => onChange(format(value!))}
            />
          )}
        />
      </IonItem>
      {errors.dni && (
        <IonItem lines="none">
          <InputErrorMessage errorMessage={errors.dni?.message || "RUT inválido"} />
        </IonItem>
      )}

      <IonItem lines="inset">
        <IonLabel position="stacked">Email</IonLabel>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("email", {
                required: "Campo obligatorio.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Por favor, verifique el email.",
                },
              })}
            />
          )}
        />
      </IonItem>
      {errors.email && (
        <InputErrorMessage errorMessage={errors.email?.message || ""} paddingLeft={15} />
      )}
      
      <IonItem lines="inset">
        <IonLabel position="stacked">Telefono</IonLabel>
        <Controller
          control={control}
          name="celular"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("celular", {
                required: "Campo obligatorio.",
              })}
            />
          )}
        />
      </IonItem>
      {errors.celular && (
        <InputErrorMessage errorMessage={errors.celular?.message || ""} paddingLeft={15} />
      )}

      <IonItem lines="inset">
        <IonLabel position="stacked">Estado</IonLabel>
        <Controller
          control={control}
          name="isSignatureEnabled"
          render={({ field: { onChange, value } }) => (
            
            <IonToggle
              onIonChange={(e) => onChange(e.detail.checked)}
              checked={value}
            />
          )}
        />
      </IonItem>
      {errors.isSignatureEnabled && (
        <InputErrorMessage errorMessage={errors.isSignatureEnabled?.message || ""} paddingLeft={15} />
      )}

      <FormButtonSubmit />
    </form>
  );
};

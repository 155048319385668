import { IonInfiniteScroll, IonInfiniteScrollContent, IonItemDivider, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { incrementPage } from "../../../store/users";
import findUsers from "../../../store/users/actions/findUsers";
import { selectUsersFiltered } from "../../../store/users/selectors/selectUsersFiltered";
import { UserListItem } from "./UserListItem";

export const UsersListContainer: React.FC = () => {

  const dispatch = useDispatch();
  
  const users = useSelector(selectUsersFiltered);

  const user = useSelector(selectUser);

  const organizationId:any = user?.workingOrganization;
  
  const [infiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);

  useEffect(() => {    
    if (users.length == 0) {
      setInfiniteDisabled(false);
    }
  }, [users]);

  const loadData = (ev: any) => {
    setTimeout(() => {
      dispatch(incrementPage());
      dispatch(findUsers(organizationId));
      ev.target.complete();
      if (users.length % 10 !== 0) {
        setInfiniteDisabled(true);
      }
    }, 500);
  }

  return (
    <>
      <IonItemDivider class="ion-margin-bottom">
        <IonLabel>
          <p>{users.length} resultados</p>
        </IonLabel>
      </IonItemDivider>
      {users.map((user) => (
        <UserListItem user={user} key={user.id} />
      ))}
      <IonInfiniteScroll onIonInfinite={loadData} threshold="10px" disabled={infiniteDisabled}>
        <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Cargando Usuarios ..." />
      </IonInfiniteScroll>
    </>
  );
};

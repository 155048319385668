import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Archive } from "../../../@types/archive";
import axios from "axios";
import { RootState } from "../../rootReducer";

const createArchive = createAsyncThunk(
  "userAuthenticated/createArchive",
  async (file: any, { getState, rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const { data: archiveCreated } = await axios.post(`${api.baseURL}file/create-archivo`, formData);

      const state = getState() as RootState;
      await axios.patch(`${api.baseURL}user/${state.userAuthentication.user?.id}`, {
        profilePicture: archiveCreated.id,
      });

      return archiveCreated as Archive;
    } catch ({ message }) {
      rejectWithValue(message);
      throw message;
    }
  }
);

export default createArchive;

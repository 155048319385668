import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserInscriptionState {
    formValidation: boolean | null;
    errors: { [key: string]: string | null };
    showErrors:boolean;
    inscriptionData:any[];
    nextStep:boolean;
    inscriptionCalendar: any;
    purchaseConf:any[];
    sameBuyer:boolean
}

const initialState: UserInscriptionState = {
    formValidation: null,
    errors: {},
    showErrors: false,
    inscriptionData:[],
    nextStep:false,
    inscriptionCalendar: null,
    purchaseConf:[],
    sameBuyer:false,
};

export const resetForm = createAction("RESET_FORM_STATE");
export const showErrors = createAction<boolean>("SHOW_ERRORS")
export const setInscriptionData = createAction<any>("SET_INSCRIPTION_DATA")
export const setNextStep = createAction<boolean>("SET_NEXT_STEP")
export const setInscriptionCalendar = createAction<any>("SET_CALENDAR_DATA")
export const setIsSameBuyer = createAction<boolean>("SET_IS_SAME_BUYER")
export const setPurchaseConf = createAction<any>("SET_PURCHASE_CONFIRMATION")

const userInscriptionSlice = createSlice({
  name: "userInscription",
  initialState,
  reducers: {
    setFormValidity: (state, action: PayloadAction<boolean>) => {
      state.formValidation = action.payload;
    },
    setErrors: (state, action: PayloadAction<{ [key: string]: string | null }>) => {
      state.errors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetForm, (state) => {
        state.formValidation = null;
        state.errors = {};
      })
      .addCase(showErrors, (state, {payload}) => {
        state.showErrors = payload
      })
      .addCase(setInscriptionData, (state, {payload}) => {
        state.inscriptionData = payload
      })
      .addCase(setInscriptionCalendar, (state, {payload}) => {
        state.inscriptionCalendar = payload
      })
      .addCase(setPurchaseConf, (state, {payload}) => {
        state.purchaseConf = payload
      })
      .addCase(setIsSameBuyer, (state, {payload}) => {
        state.sameBuyer = payload
      })
      .addCase(setNextStep, (state, {payload})=>{
        state.nextStep = payload
      });
  },
});

export const { setFormValidity, setErrors } = userInscriptionSlice.actions;

export default userInscriptionSlice.reducer;

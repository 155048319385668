import { useEffect } from "react";

import { useDispatch } from "react-redux";
import subscribeNotifications from "../store/userAuthentication/actions/subscribeNotifications";
import { validateSession } from "../store/userAuthentication/actions/validateSession";

export const FetchInitialData: React.FC = () => {
  console.log("Fetch Data Render");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscribeNotifications());
    dispatch(validateSession());
  }, [dispatch]);

  return <></>;
};

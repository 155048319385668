import {
  albumsOutline,
  calendarOutline,
  homeOutline,
  idCardOutline,
  layersOutline,
  listOutline,
  locationOutline,
  peopleOutline,
  pricetagOutline,
  statsChartOutline,
  documentTextOutline,
  documentsOutline,
  cashOutline,
  calendarNumberOutline
} from "ionicons/icons";
import { AppPage } from "../@types/appPage";
import { UserAuthenticatedAccount } from "../pages/UserAuthenticatedAccount";
import { UserAuthUpdate } from "../pages/UserAuthUpdate";
import { UserCreate } from "../pages/UserCreate";
import { UserProfiles } from "../pages/UserProfiles";
import { UserShow } from "../pages/UserShow";
import { UsersList } from "../pages/UsersList";
import { UserUpdate } from "../pages/UserUpdate";
import { DashboardMain } from "../pages/Dashboard";
import { ExamsCalendarMain } from "../pages/ExamsCalendar";
import { InscriptionsMain } from "../pages/Inscriptions";
import { AddNewCalendarExam } from "../pages/ExamsCalendar/components/AddNewExamCalendar";
import { EditInscription } from "../pages/Inscriptions/components/EditInscription";
import { EditExamCalendar } from "../pages/ExamsCalendar/components/EditCalendarExam";
import { ExamsMain } from "../pages/Exams";
import { AddNewExam } from "../pages/Exams/components/AddNewExam";
import { EditExam } from "../pages/Exams/components/EditExam";

export const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/app/dashboard",
    component: DashboardMain,
    icon: statsChartOutline,
    restricted: false,
    menu: true,
    exact: true,
  },
  {
    title: "Calendario de Exámenes",
    url: "/app/calendario-examenes",
    component: ExamsCalendarMain,
    icon: calendarNumberOutline,
    restricted: false,
    menu: true,
    exact: true,
  },
  {
    title: "Añadir Examen",
    url: "/app/calendario-examenes/anadir-calendario",
    component: AddNewCalendarExam,
    icon: calendarNumberOutline,
    restricted: false,
    menu: false,
    exact: true,
  },
  {
    title: "Editar Examen",
    url: "/app/calendario-examenes/editar-examen",
    component: EditExamCalendar,
    icon: calendarNumberOutline,
    restricted: false,
    menu: false,
    exact: true,
  },
  {
    title: "Examenes",
    url: "/app/examenes",
    component: ExamsMain,
    icon: documentTextOutline,
    restricted: false,
    menu: true,
    exact: true,
  },
  {
    title: "Examenes",
    url: "/app/examenes/anadir-examen",
    component: AddNewExam,
    icon: documentTextOutline,
    restricted: false,
    menu: false,
    exact: true,
  },
  {
    title: "Editar Exámenes",
    url: "/app/examenes/editar-examen/:id",
    component: EditExam,
    icon: documentsOutline,
    restricted: false,
    menu: false,
    exact: true,
  },
  {
    title: "Inscripciones",
    url: "/app/inscripciones",
    component: InscriptionsMain,
    icon: documentsOutline,
    restricted: false,
    menu: true,
    exact: true,
  },
  {
    title: "Ficha de Inscripción",
    url: "/app/inscripciones/editar-ficha/:id",
    component: EditInscription,
    icon: documentsOutline,
    restricted: false,
    menu: false,
    exact: true,
  },
  {
    title: "Mi Cuenta",
    url: "/app/mi-cuenta/actualizar",
    component: UserAuthUpdate,
    icon: homeOutline,
    restricted: true,
    menu: false,
    exact: true,
  },
  {
    title: "Usuarios",
    url: "/app/administrador/usuarios",
    component: UsersList,
    icon: peopleOutline,
    restricted: true,
    menu: true,
    exact: true,
  },
  {
    title: "Usuarios",
    url: "/app/administrador/usuarios/:id",
    component: UserShow,
    icon: peopleOutline,
    restricted: true,
    menu: false,
    exact: true,
  },
  {
    title: "Usuarios",
    url: "/app/administrador/usuarios/:id/editar",
    component: UserUpdate,
    icon: peopleOutline,
    restricted: true,
    menu: false,
    exact: true,
  },
  {
    title: "Usuarios",
    url: "/app/administrador/usuarios/:id/editarperfiles",
    component: UserProfiles,
    icon: peopleOutline,
    restricted: true,
    menu: false,
    exact: true,
  },
  {
    title: "Usuarios",
    url: "/app/administrador/usuarios/nuevo",
    component: UserCreate,
    icon: peopleOutline,
    restricted: true,
    menu: false,
    exact: true,
  },
  {
    title: "Mi Cuenta",
    url: "/app/mi-cuenta",
    component: UserAuthenticatedAccount,
    icon: homeOutline,
    restricted: true,
    menu: true,
    exact: true,
  },
];

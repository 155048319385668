import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonDatetime,
  IonInput,
  IonItem,
  IonGrid,
  IonButton,
  IonIcon,
  IonText,
  IonToast
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { addOutline } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import { setInitState } from "../../../store/exams";
import { modifyExamCalendar } from "../../../store/exams/actions/modifyExamCalendar";

export const EditExamCalendar: React.FC = () => {
  const location: any = useLocation();
  const dispatch : any = useDispatch()

  const data = location.state ? location.state.item : [];
  const [selectedExam, setSelectedExam] = useState<any>(data.id_exam || "");
  const [selectedRegion, setSelectedRegion] = useState<any>(data.id_region || "");
  const [selectedCommune, setSelectedCommune] = useState<any>(data.id_commune || "");
  const [examDate, setExamDate] = useState<any>(data.date || null);
  const [examValue, setExamValue] = useState<any>(data.price || "");

  const allExams = useSelector((state: any) => state.exams?.exams);
  const examsCalendar = useSelector((state: any) => state.exams?.examsCalendar);
  const allCommunes = useSelector((state: any) => state.exams?.communes);
  const allRegions = useSelector((state: any) => state.exams?.regions);
  const modifyExamStatus = useSelector(
    (state: any) => state.exams.modifyExamStatus
  );

  useEffect(() => {
    const selectedCommuneData = allCommunes.find((commune : any) => commune.id === data.id_commune);
    const regionId = selectedCommuneData ? selectedCommuneData.id_region : "";
  
    setSelectedCommune(data.id_commune || "");
    setSelectedRegion(regionId);
  }, [allCommunes, data]);


  useEffect(() => {
    dispatch(setInitState());
  }, []);


  const handleEditExam = () => {
      dispatch(
        modifyExamCalendar({
          date: examDate,
          price: examValue,
          id_exam: selectedExam,
          id_commune: selectedCommune,
          id_calendar:data.id
        })
      );
  };
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/calendario-examenes" color="light" />
          </IonButtons>
          <IonTitle>Editar Calendario de Examen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="8" sizeLg="6">
                <IonTitle style={{ paddingInline: 0 }}>
                  Editar Calendario de Examen
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div style={{}}>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Examen:</IonLabel>
                  <IonSelect
                    value={selectedExam}
                    placeholder="Tipo de Examen"
                    onIonChange={(e: any) => setSelectedExam(e.detail.value)}
                  >
                    {allExams.map((exam: any) => (
                      <IonSelectOption key={exam.id} value={exam.id}>
                        {exam.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Región:</IonLabel>
                  <IonSelect
                    value={selectedRegion}
                    placeholder="Seleccione Región"
                    onIonChange={(e: any) =>
                      setSelectedRegion(Number(e.detail.value))
                    }
                  >
                    {allRegions.map((region: any) => (
                      <IonSelectOption key={region.id} value={region.id}>
                        {region.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>
                    <h2>Comuna:</h2>
                  </IonLabel>
                  <IonSelect
                    value={selectedCommune}
                    placeholder="Seleccione Comuna"
                    onIonChange={(e: any) => setSelectedCommune(e.detail.value)}
                    style={{ width: "100%" }}
                  >
                    {allCommunes
                      .filter(
                        (commune: any) => commune.id_region === selectedRegion
                      )
                      .map((commune: any) => (
                        <IonSelectOption key={commune.id} value={commune.id}>
                          {commune.name}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Fecha del Examen:</IonLabel>
                <IonDatetime
                  value={examDate}
                  onIonChange={(e: any) => setExamDate(e.detail.value)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonItem>
                  <IonLabel>Valor: $</IonLabel>
                  <IonInput
                    value={examValue}
                    placeholder="Ingrese Valor en pesos"
                    onIonChange={(e: any) => setExamValue(e.detail.value)}
                    type="number"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 20,
              }}
            >
              <IonCol size="3">
                <IonButton onClick={handleEditExam}>
                  <IonIcon icon={addOutline} slot="start"></IonIcon>
                  <IonText>Actualizar Calendario de Examen</IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </section>
      </IonContent>
      <IonToast
        isOpen={modifyExamStatus === "loading"}
        message="Modificando Calendario de Examen..."
        position="bottom"
        duration={6000}
        color="primary"
      />

      {/* Success Toast */}
      <IonToast
        isOpen={modifyExamStatus === "succeeded"}
        message="Calendario De Examen Modificado"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())}  // Reset the state
        duration={6000}
        color="success"
      />

      {/* Error Toast */}
      <IonToast
        isOpen={modifyExamStatus === "failed"}
        message="A ocurrido un error"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())}  // Reset the state
        duration={6000}
        color="danger"
      />
    </IonPage>
  );
};

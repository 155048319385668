import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonCard,
  IonNote,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { format, validate } from "rut.js";
import {
  setErrors,
  setFormValidity,
  setInscriptionData,
  setNextStep,
} from "../../../store/userInscriptions";
import { useDispatch } from "react-redux";

interface PostulantDataProps {
  names?: any;
  setSelectedNames?: any;
  lastName?: any;
  setLastName?: any;
  birthDate?: any;
  setBirthDate?:any;
  rut?: any;
  setRut?:any;
  gender?: any;
  setGender?:any;
  selectedRegion?: any;
  setSelectedRegion?:any;
  selectedCommune?: any;
  setSelectedCommune?:any;
  address?: any;
  setAddress?:any;
  phone?: any;
  setPhone?:any;
  email?: any;
  setEmail?:any
}

export const PostulantData: React.FC<PostulantDataProps> = ({names,
  setSelectedNames,
  lastName,
  setLastName,
  birthDate,
  setBirthDate,
  rut,
  setRut,
  gender,
  setGender,
  selectedRegion,
  setSelectedRegion,
  selectedCommune,
  setSelectedCommune,
  address,
  setAddress,
  phone,
  setPhone,
  email,
  setEmail}:any) => {
  const dispatch: any = useDispatch();
  const allCommunes = useSelector((state: any) => state.exams?.communes);
  const allRegions = useSelector((state: any) => state.exams?.regions);
  const [isValidRut, setIsValidRut] = useState(true);

  const handleChange = (e: any) => {
    const inputValue = e.detail.value;
    const cleanRut = format(inputValue);
    setRut(cleanRut);
    setIsValidRut(validate(cleanRut));
  };

  const validateInputs = () => {
    const isValidName = names.length > 0;

    const isValidLastName = lastName.length > 0;

    const isValidBirthDate = Date.parse(birthDate) < Date.now();

    const isValidGender = ["Masculino", "Femenino"].includes(gender);

    const isValidRegion = allRegions.some(
      (region: any) => region.id === selectedRegion
    );

    const isValidCommune = allCommunes.some(
      (commune: any) =>
        commune.id === selectedCommune && commune.id_region === selectedRegion
    );

    const isValidAddress = address.length > 0;

    const isValidPhone = /^\+?[1-9]\d{1,14}$/.test(phone);

    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );

    const errors = {
      names: isValidName ? null : "Ingrese un Nombre",
      lastName: isValidLastName ? null : "Ingrese un Apellido",
      birthDate: isValidBirthDate ? null : "Fecha de Nacimiento Invalida",
      gender: isValidGender ? null : "Ingrese genero",
      selectedRegion: isValidRegion ? null : "Seleccione Región",
      selectedCommune: isValidCommune ? null : "Seleccione Comuna",
      address: isValidAddress ? null : "Ingrese Dirección",
      phone: isValidPhone ? null : "Teléfono Invalido",
      email: isValidEmail ? null : "Email Invalido",
    };

    const isFormValid =
      isValidRut &&
      isValidName &&
      isValidLastName &&
      isValidBirthDate &&
      isValidGender &&
      isValidRegion &&
      isValidCommune &&
      isValidAddress &&
      isValidPhone &&
      isValidEmail;

    dispatch(setFormValidity(isFormValid));
    dispatch(setErrors(errors));

    dispatch(
      setInscriptionData({
        names: names,
        lastName: lastName,
        birthDate: birthDate,
        rut: rut,
        gender: gender,
        region: selectedRegion,
        commune: selectedCommune,
        address: address,
        phone: phone,
        email: email,
      })
    );
  };

  const errors = useSelector((state: any) => state.userInscription.errors);
  const showErrors = useSelector(
    (state: any) => state.userInscription.showErrors
  );

  useEffect(() => {
    validateInputs();
  }, [
    names,
    lastName,
    birthDate,
    rut,
    gender,
    selectedRegion,
    selectedCommune,
    address,
    phone,
    email,
  ]);

  useEffect(() => {
    dispatch(setNextStep(false));
  }, []);

  return (
    <>
      <IonGrid>
        <IonRow style={{ marginTop: 40, marginBottom: 20 }}>
          <IonCol>
            <IonTitle>Datos del Postulante</IonTitle>
          </IonCol>
        </IonRow>
        <IonCard style={{ padding: 15 }}>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Nombres"
                  type="text"
                  value={names}
                  onIonChange={(e: any) => setSelectedNames(e.detail.value)}
                />
                {showErrors && errors.names && (
                  <IonNote color="danger">{errors.names}</IonNote>
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Apellidos"
                  type="text"
                  value={lastName}
                  onIonChange={(e: any) => setLastName(e.detail.value)}
                />
                {showErrors && errors.lastName && (
                  <IonNote color="danger">{errors.lastName}</IonNote>
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Fecha Nacimiento"
                  type="date"
                  value={birthDate}
                  onIonChange={(e: any) => setBirthDate(e.detail.value)}
                />
                {showErrors && errors.birthDate && (
                  <IonNote color="danger">{errors.birthDate}</IonNote>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  value={rut}
                  placeholder="Rut"
                  type="text"
                  onIonChange={handleChange}
                />
                {!isValidRut && (
                  <div style={{ color: "red" }}>RUT no valido</div>
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel style={{ display: "none" }}>Genero</IonLabel>
                <IonSelect
                  placeholder="Genero"
                  onIonChange={(e: any) => setGender(e.detail.value)}
                  value={gender}
                >
                  <IonSelectOption>Masculino</IonSelectOption>
                  <IonSelectOption>Femenino</IonSelectOption>
                </IonSelect>
                {showErrors && errors.gender && (
                  <IonNote color="danger">{errors.gender}</IonNote>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCard>

        <IonRow style={{ marginTop: 40 }}>
          <IonCol>
            <IonTitle>Datos de Contacto</IonTitle>
          </IonCol>
        </IonRow>
        <IonCard style={{ padding: 15 }}>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel style={{ display: "none" }}>Región</IonLabel>
                <IonSelect
                  value={selectedRegion}
                  placeholder="Seleccione Región"
                  onIonChange={(e: any) =>
                    setSelectedRegion(Number(e.detail.value))
                  }
                >
                  {allRegions.map((region: any) => (
                    <IonSelectOption key={region.id} value={region.id}>
                      {region.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {showErrors && errors.selectedRegion && (
                  <IonNote color="danger">{errors.selectedRegion}</IonNote>
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel style={{ display: "none" }}>Comuna</IonLabel>
                <IonSelect
                  value={selectedCommune}
                  placeholder="Seleccione Comuna"
                  onIonChange={(e: any) => setSelectedCommune(e.detail.value)}
                  style={{ width: "100%" }}
                >
                  {allCommunes
                    .filter(
                      (commune: any) => commune.id_region === selectedRegion
                    )
                    .map((commune: any) => (
                      <IonSelectOption key={commune.id} value={commune.id}>
                        {commune.name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
                {showErrors && errors.selectedCommune && (
                  <IonNote color="danger">{errors.selectedCommune}</IonNote>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Dirección"
                  value={address}
                  onIonChange={(e: any) => setAddress(e.detail.value)}
                />
                {showErrors && errors.address && (
                  <IonNote color="danger">{errors.address}</IonNote>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Teléfono"
                  value={phone}
                  onIonChange={(e: any) => setPhone(e.detail.value)}
                />
                {showErrors && errors.phone && (
                  <IonNote color="danger">{errors.phone}</IonNote>
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonInput
                  placeholder="Correo"
                  type="text"
                  value={email}
                  onIonChange={(e: any) => setEmail(e.detail.value)}
                />
                {showErrors && errors.email && (
                  <IonNote color="danger">{errors.email}</IonNote>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCard>
      </IonGrid>
    </>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getExams } from "./getExams";

export const modifyExam = createAsyncThunk(
  "exams/modifyExam",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`${api.baseURL}exams`, data);
      dispatch(getExams());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

import { IonApp } from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { UserAuthenticatedRouter } from "./components/UserAuthenticatedRouter";
// Pages
import { Login } from "./pages/Login";
import { PasswordRecovery } from "./pages/PasswordRecovery";
/* Theme variables */
import "./theme/variables.css";
import "./theme/styles.css";
import { setupIonicReact } from "@ionic/react";
import { UserInscription } from "./pages/UserInscription";

setupIonicReact({
  mode: "md",
});

const App: React.FC = () => {
  console.log("App Render");
  return (
    <IonApp>
      <Switch>
        <Route path="/app/*">
          <UserAuthenticatedRouter />
        </Route>
        <Route path="/ingresar">
          <Login />
        </Route>
        <Route path="/recuperar">
          <PasswordRecovery />
        </Route>
        <Route path="/inscripciones">
          <UserInscription />
        </Route>
        <Redirect to="/ingresar" />
      </Switch>
    </IonApp>
  );
};

export default App;

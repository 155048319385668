import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const findUserOrganization = createAsyncThunk(
    "userCreate/findUserOrganization",
    async (parametros: any, { getState, rejectWithValue }) => {
        //console.log("findUserOrganization.ts: parametros: ", parametros);
        try {

            const findUserOrganization = axios.get(`${api.baseURL}userOrganization/import/${parametros.organizationId}`, {
                params: {
                    dni: parametros.dni,
                },
            });

            const data = await findUserOrganization;

            console.log("findUserOrganization.ts: data.data: ", data.data);

            return data.data;

        } catch (err) {
            return rejectWithValue(err);
        }
    }
)
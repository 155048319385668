import { combineReducers } from "@reduxjs/toolkit";
import userAuthentication from "./userAuthentication/userAuthentication.reducer";
import profileForm from "./profileForm/profileForm.reducer";
import profileDetail from "./profileDetail/profileDetail.reducer";
import { supervisorHome } from "./supervisorHome";
import users from "./users";
import userShow from "./userShow";
import userCreate from "./userCreate";
import userProfiles from "./userProfiles";
import exams from "./exams";
import inscriptions from "./inscriptions";
import userInscription from "./userInscriptions"

const rootReducer = combineReducers({
  users,
  userAuthentication,
  profileForm,
  profileDetail,
  supervisorHome,
  userShow,
  userCreate,
  userProfiles,
  exams,
  inscriptions,
  userInscription

});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

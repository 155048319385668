import { IonPage } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectAppPages } from "../../store/userAuthentication/selectors/selectAppPages";
import { selectIsAuthenticated } from "../../store/userAuthentication/selectors/selectIsAuthenticated";
import { selectRedirect } from "../../store/userAuthentication/selectors/selectRedirect";
import { LoginForm } from "./components/LoginForm";

export const Login: React.FC = () => {
  console.log("Login Render");
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const appPages = useSelector(selectAppPages);
  const redirect = useSelector(selectRedirect);

  if (isAuthenticated) {
    return <Redirect to={redirect || appPages[0].url} />;
  }

  return (
    <IonPage>
      <LoginForm />
    </IonPage>
  );
};

import { IonToast } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateStatus } from "../../../store/userAuthentication/userAuthentication.reducer";
import { selectUpdateStatus } from "../../../store/userAuthentication/selectors/selectUpdateStatus";
export const SuccessToast = () => {
  const dispatch = useDispatch();
  const updateStatus = useSelector(selectUpdateStatus);
  return (
    <IonToast
      isOpen={updateStatus === "fulfilled"}
      onDidDismiss={() => dispatch(setUpdateStatus())}
      message="El usuario fue actualizado correctamente."
      color="success"
      duration={5000}
      position="top"
    />
  );
};

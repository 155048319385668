import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
} from "@ionic/react";
import { DashboardIndicators } from "./components/DashboardIndicators";
import { PieChart } from "./components/PieChart";
import { BarChart } from "./components/BarChart";
import axios from "axios";
import api from "../../utils/api";

export const DashboardMain: React.FC = () => {

  const colorPalette = ['#FF6F61', '#6B5B95', '#88B04B', '#F7CAC9', '#92A8D1', '#955251', '#B565A7', '#009B77', '#D65076'];

const getColorFromPalette = (index: number) => {
  return colorPalette[index % colorPalette.length];
};

  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api.baseURL}dashboard`);
        setData(response.data);
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      }
    };
    fetchData();
  }, []);

  const generateChartData = (inputData: any) => {
    if (!inputData) return {};

    // Convert payments object to an array suitable for the PieChart
    const payments = Object.keys(inputData.payments).map((key, index) => ({
      type: key,
      value: inputData.payments[key],
      color: getColorFromPalette(index),
    }));

    // Inscriptions by Exam
    const inscriptionExam = inputData.inscriptionExam.map((item: any, index :any) => ({
      type: item.exam,
      value: item.total,
      color: getColorFromPalette(index),
    }));

    // Inscriptions by Commune
    const inscriptionCommune = inputData.inscriptionCommune.map(
      (item: any, index: any) => ({
        type: item.commune,
        value: item.total,
        color: getColorFromPalette(index),
      })
    );

    // Average Age of Inscriptions
    const inscriptionAges = inputData.inscriptionAges.map((item: any, index:any) => ({
      type: "EDAD PROMEDIO",
      value: item.averageAge,
      color: getColorFromPalette(index),
    }));

    // Exams by Month
    const examByMonth = inputData.examByMonth.map((item: any, index:any) => ({
      type: `${item.month} ${item.year}`,
      value: item.total,
      color: getColorFromPalette(index),
    }));

    return {
      payments,
      inscriptionExam,
      inscriptionCommune,
      inscriptionAges,
      examByMonth,
    };
  };

  const chartData = generateChartData(data);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="light" />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonTitle
                  style={{
                    paddingInline: 0,
                    textAlign: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  Indicadores
                </IonTitle>
              </IonCol>
            </IonRow>
            <IonRow style={{ justifyContent: "center" }}>
              <DashboardIndicators />
            </IonRow>
            <IonGrid style={{ marginTop: 20 }}>
              <IonRow>
                <IonCol size="4">
                  <IonRow>
                    <IonCol>
                      <IonTitle style={{ textAlign: "center" }}>Pagos</IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      {chartData.payments && (
                        <PieChart data={chartData.payments} />
                      )}
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="4">
                  <IonRow>
                    <IonCol>
                      <IonTitle style={{ textAlign: "center" }}>
                        Inscripciones por examen
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  {chartData.inscriptionExam && (
                    <PieChart data={chartData.inscriptionExam} />
                  )}
                </IonCol>
                <IonCol size="4">
                  <IonRow>
                    <IonCol>
                      <IonTitle style={{ textAlign: "center" }}>
                        Inscripciones por comuna
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  {chartData.inscriptionCommune && (
                    <PieChart data={chartData.inscriptionCommune} />
                  )}
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: 20, textAlign: "center" }}>
                <IonTitle></IonTitle>
              </IonRow>
              <IonRow style={{ marginTop: 20 }}>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonTitle style={{ textAlign: "center" }}>
                        Edad promedio de inscritos
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  {chartData.inscriptionAges && (
                    <BarChart data={chartData.inscriptionAges} />
                  )}
                </IonCol>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonTitle style={{ textAlign: "center" }}>
                        Inscripciones por mes
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  {chartData.examByMonth && (
                    <BarChart data={chartData.examByMonth} />
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </section>
      </IonContent>
    </IonPage>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getExamsCalendar } from "./getExamsCalendar";

export const modifyExamCalendar = createAsyncThunk(
  "exams/modifyExamCalendar",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`${api.baseURL}calendar_exam`, data);
      dispatch(getExamsCalendar());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

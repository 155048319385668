import { IonButton, IonSpinner } from "@ionic/react";
import { useSelector } from "react-redux";
import { selectUpdateStatus } from "../../../store/userAuthentication/selectors/selectUpdateStatus";

export const FormButtonSubmit: React.FC = () => {
  const updateStatus = useSelector(selectUpdateStatus);
  return (
    <IonButton
      type="submit"
      expand="block"
      style={{ marginTop: 40 }}
      disabled={updateStatus === "pending"}
    >
      {updateStatus === "pending" ? <IonSpinner /> : "Actualizar"}
    </IonButton>
  );
};

import { IonPage } from "@ionic/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectLoginStatus } from "../../store/userAuthentication/selectors/selectLoginStatus";
import { resetTemporaryData } from "../../store/userAuthentication/userAuthentication.reducer";
import { ContentSection } from "./components/ContentSection";
import { Header } from "./components/Header";

export const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const loginStatus = useSelector(selectLoginStatus);

  useEffect(() => {
    dispatch(resetTemporaryData());
  }, [dispatch]);

  if (loginStatus === "fulfilled") {
    return <Redirect to="/app" />;
  }
  return (
    <IonPage>
      <Header />
      <ContentSection />
    </IonPage>
  );
};

import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonSearchbar,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { InscriptionsTable } from "./components/InscriptionsTable";
import { getInscriptions } from "../../store/inscriptions/actions/getInscriptions";

export const InscriptionsMain: React.FC = () => {
  const dispatch: any = useDispatch();
  const data = useSelector((state:any)=> state.inscriptions?.inscriptions) ?? []
  
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    dispatch(getInscriptions());
  }, []);

  // Effect to filter the data whenever it or the search text changes
  useEffect(() => {
    const lowerSearchText = searchText.toLowerCase();
    const filtered = data.filter((item : any) => item.inscription.name.toLowerCase().includes(lowerSearchText));
    setFilteredData(filtered);
  }, [searchText, data]);

  const handleSearch = (e: CustomEvent) => {
    setSearchText(e.detail.value);
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="light" />
          </IonButtons>
          <IonTitle>Inscripciones</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Inscripciones</IonTitle>
          </IonToolbar>
        </IonHeader>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="6" sizeMd="6" sizeLg="6" style={{alignContent:"center", display:"flex" }}>
                <IonTitle style={{ paddingInline: 0}}>Inscripciones</IonTitle>
              </IonCol>
              <IonCol
                size="6"
                sizeMd="6"
                sizeLg="6"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IonSearchbar
                  style={{ width: "50%" }}
                  debounce={700}
                  placeholder={"Buscar Candidato..."}
                  autocomplete="off"
                  value={searchText}
                  onIonChange={handleSearch}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <InscriptionsTable data={filteredData} />
        </section>
      </IonContent>
    </IonPage>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Message } from "../../../@types/message";
import axios from "axios";
export const findMessages = createAsyncThunk(
  "users/findMessages",
  async (searchText, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${api.baseURL}message`, {
        params: {
          sort: "createdAt DESC",
          populate: ["createdBy", "document"].join(","),
        },
      });
      return data as Message[];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

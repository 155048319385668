import { IonToast } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateStatus } from "../../../store/userCreate";
import { selectUserCreateStatus } from "../../../store/userCreate/selectors/selectUserCreateStatus";

export const SuccessToast = () => {
  const dispatch = useDispatch();
  const createStatus = useSelector(selectUserCreateStatus);
  return (
    <IonToast
      isOpen={createStatus === "rejected"}
      onDidDismiss={() => dispatch(setCreateStatus())}
      message="El usuario existe."
      color="danger"
      duration={6000}
      position="top"
    />
  );
};

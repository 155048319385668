import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { requestRecoveryToken } from "../../../store/userAuthentication/actions/requestRecoveryToken";
import { selectError } from "../../../store/userAuthentication/selectors/selectError";
import { setTempEmail } from "../../../store/userAuthentication/userAuthentication.reducer";
import { FormButtonSubmit } from "./FormButtonSubmit";

export const PasswordRecoveryRequest = () => {
  const dispatch = useDispatch();
  const recoveryError = useSelector(selectError);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = async (data: any) => {
    try {
      await dispatch(requestRecoveryToken(data.email));
      dispatch(setTempEmail(data.email));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }} noValidate>
      <IonItem lines="inset">
        <IonLabel position="stacked">Email</IonLabel>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              placeholder="ejemplo@mail.com"
              value={value}
              {...register("email", {
                required: "Campo obligatorio.",
              })}
            />
          )}
        />
      </IonItem>
      {errors.email && (
        <InputErrorMessage errorMessage={errors.email?.message || ""} paddingLeft={15} />
      )}
      {recoveryError && (
        <InputErrorMessage
          errorMessage={"Hubo un error con los datos proporcionados. Inténtelo nuevamente."}
          paddingLeft={15}
        />
      )}

      <FormButtonSubmit />
    </form>
  );
};

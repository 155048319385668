import React, { useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonCheckbox,
  IonNote,
  IonText
} from "@ionic/react";
import { useSelector } from "react-redux";
import {
  setErrors,
  setFormValidity,
  setInscriptionCalendar,
  setNextStep,
  showErrors,
} from "../../../store/userInscriptions";
import { useDispatch } from "react-redux";
import { getCommuneWithExams } from "../../../store/inscriptions/actions/getCommunesWithExams";

interface ExamSelectionProps{
selectedCommune?:any;
setSelectedCommune?:any;
selectedExamCalendars?:any;
setSelectedExamCalendars?:any
selectedExams?:any;
setSelectedExams?:any
}

export const ExamSelection: React.FC<ExamSelectionProps> = ({selectedCommune,
  setSelectedCommune,
  selectedExamCalendars,
  setSelectedExamCalendars,
  selectedExams,
  setSelectedExams}:any) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(setFormValidity(false));
    dispatch(showErrors(false));
    dispatch(setNextStep(false));
    dispatch(getCommuneWithExams())
  }, []);

  const errors = useSelector((state: any) => state.userInscription.errors);
  const showCalendarErrors = useSelector(
    (state: any) => state.userInscription.showErrors
  );

  const allCommunes = useSelector((state: any) => state.inscriptions?.communesWithExams);
  const allExamsCalendars = useSelector(
    (state: any) => state.exams?.examsCalendar
  );
  const allExams = useSelector((state: any) => state.exams?.exams);

  useEffect(() => {
    if (selectedCommune) {
      const examCalendars = allExamsCalendars.filter(
        (calendar: any) => calendar.id_commune === selectedCommune
      );
      const examCalendarWithExamInfo = examCalendars.map((calendar: any) => {
        const exam = allExams.find((exam: any) => exam.id === calendar.id_exam);
        return { ...calendar, examName: exam ? exam.name : "" };
      });
      setSelectedExamCalendars(examCalendarWithExamInfo);
    }
  }, [selectedCommune]);

  const handleSelectExam = (examId: any) => {
    if (selectedExams.includes(examId)) {
      const updatedSelectedExams = selectedExams.filter((id: any) => id !== examId);
      setSelectedExams(updatedSelectedExams);
      dispatch(setInscriptionCalendar({ examsForPurchase: updatedSelectedExams }));
    } else {
      const updatedSelectedExams = [...selectedExams, examId];
      setSelectedExams(updatedSelectedExams);
      dispatch(setInscriptionCalendar({ examsForPurchase: updatedSelectedExams }));
    }
  };
  

  useEffect(() => {
    if (selectedExams.length > 0) {
      dispatch(setFormValidity(true));
      dispatch(setNextStep(true));
    } else {
      const error = {
        examCalendar: "Debe seleccionar al menos un Examen",
      };
      dispatch(setErrors(error));
      dispatch(setFormValidity(false));
    }
  }, [selectedExams]);

  return (
    <>
      <IonGrid>
        <IonRow style={{ marginTop: 40, marginBottom: 20 }}>
          <IonCol>
            <IonTitle style={{ paddingInline: 0 }}>
              Selección de Exámenes
            </IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel>Seleccione comuna de rendición de examen.</IonLabel>
            <IonItem style={{ maxWidth: "50%", marginTop: 10 }}>
              <IonLabel style={{ display: "none" }}>Comuna</IonLabel>
              <IonSelect
                value={selectedCommune}
                placeholder="Seleccione Comuna"
                onIonChange={(e: any) => setSelectedCommune(e.detail.value)}
                style={{ width: "100%" }}
              >
                {allCommunes.map((commune: any) => (
                  <IonSelectOption key={commune.id} value={commune.id}>
                    {commune.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        {selectedExamCalendars.length > 0 ? (
          <>
            <IonRow>
              <IonCol>Examen</IonCol>
              <IonCol>Fecha</IonCol>
              <IonCol>Valor Examen</IonCol>
              <IonCol>Seleccion</IonCol>
            </IonRow>
            {selectedExamCalendars.map((examCalendar: any, index: any) => (
              <IonRow key={index}>
                <IonCol>{examCalendar.examName}</IonCol>
                <IonCol>
                  {new Date(examCalendar.date).toLocaleDateString()}
                </IonCol>
                <IonCol>${examCalendar.price}</IonCol>
                <IonCol>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      value={examCalendar.id}
                      checked={selectedExams.includes(examCalendar.id)}
                      onIonChange={() => handleSelectExam(examCalendar.id)}
                    />
                    {showCalendarErrors && errors.examCalendar && (
                      <IonNote color="danger">{errors.examCalendar}</IonNote>
                    )}
                    <IonLabel style={{ verticalAlign: "top" }}>
                      Comprar
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            ))}
          </>
        ) : (
          selectedCommune !== undefined && (
            <IonItem lines="none" style={{marginTop:20}}>
              <IonText style={{textAlign:"center", width:"100%"}}>No hay Exámenes disponibles en esta comuna.</IonText>
            </IonItem>
          )
        )}
      </IonGrid>
    </>
  );
};

import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent } from "@ionic/react"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectAppPages } from "../../store/userAuthentication/selectors/selectAppPages";
import { selectUser } from "../../store/userAuthentication/selectors/selectUser";
import { resetUserProfiles } from "../../store/userProfiles";
import { findUserProfiles } from "../../store/userProfiles/actions/findUserProfiles";
import { SuccessToast } from "./components/SuccesToast";
import { UpdateUserProfileForm } from "./components/UpdateUserProfileForm";

export const UserProfiles: React.FC = () => {

    const { id }: { id: string } = useParams();

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const organizationId:any = user?.workingOrganization;

    const appPages = useSelector(selectAppPages);

    const superUserPages = appPages.filter(({ title }) => title.includes("Perfiles") || title.includes("Organizaciones"));

    const isSuperUser = superUserPages.length > 0;

    useEffect(() => {
        dispatch(resetUserProfiles())
        dispatch(findUserProfiles({ userId: id, organizationId, isSuperUser }));
    }, [dispatch]);
    
    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/app/administrador/usuarios/${id}`} />
                    </IonButtons>
                    <IonTitle>Selector de Perfiles</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <section style={{ maxWidth: 700 }}>
                    <UpdateUserProfileForm />
                </section>
                <SuccessToast />
            </IonContent>
        </IonPage>
    )
}
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputErrorMessage from "../../../components/InputErrorMessage";
import { updateUser } from "../../../store/userAuthentication/actions/updateUser";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { FormButtonSubmit } from "../../Profile/components/FormButtonSubmit";

export const UpdateUserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: user?.email || "",
      password: "",
      celular: user?.celular || "",
    },
  });
  const onSubmit = async (data: any) => {
    try {
      if (data.email === user?.email) {
        if (data.password) {
          dispatch(updateUser(data));
          history.push("/app/mi-cuenta");
        }
        if (data.celular) {
          dispatch(updateUser(data));
          history.push("/app/mi-cuenta");
        }
      } else {
        dispatch(updateUser(data));
        history.push("/app/mi-cuenta");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }} noValidate>
      <IonItem lines="inset">
        <IonLabel position="stacked">Email</IonLabel>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("email", {
                required: "Campo obligatorio.",
              })}
            />
          )}
        />
      </IonItem>
      {errors.email && (
        <InputErrorMessage errorMessage={errors.email?.message || ""} paddingLeft={15} />
      )}

      <IonItem lines="inset">
        <IonLabel position="stacked">Contraseña</IonLabel>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              maxlength={12}
              type="password"
              placeholder="Opcional"
              {...register("password", {
                minLength: {
                  value: 4,
                  message: "La contraseña debe contener entre 4 y 12 caracteres.",
                },
                maxLength: {
                  value: 12,
                  message: "La contraseña debe contener entre 4 y 12 caracteres.",
                },
              })}
            />
          )}
        />
      </IonItem>
      {errors.password && (
        <InputErrorMessage errorMessage={errors.password?.message || ""} paddingLeft={15} />
      )}

      <IonItem lines="inset">
        <IonLabel position="stacked">Celular</IonLabel>
        <Controller
          control={control}
          name="celular"
          render={({ field: { onChange, value } }) => (
            <IonInput
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              {...register("celular", {
                required: "Campo obligatorio.",
              })}
            />
          )}
        />
      </IonItem>
      {errors.celular && (
        <InputErrorMessage errorMessage={errors.celular?.message || ""} paddingLeft={15} />
      )}

      <FormButtonSubmit />
    </form>
  );
};

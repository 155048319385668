import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRegions = createAsyncThunk(
    "exams/getAllRegions",
    async (_, { rejectWithValue }) => {
        try {

            const response = axios.get(`${api.baseURL}regions`);

            const data = await response;

            return data.data;

        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
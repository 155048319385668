import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { personOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { AccountDetailsCard } from "./components/AccountDetailsCard";
import { Helmet } from "react-helmet";
import LogoutButton from "./components/LogoutButton";

import { useDispatch } from "react-redux";
import { setLogoutStatus } from "../../store/userAuthentication/userAuthentication.reducer";

export const UserAuthenticatedAccount: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLogoutStatus(""));
  }, [dispatch]);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <Helmet>
          <title>Mi ICBC - Mi cuenta</title>
        </Helmet>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="light" />
          </IonButtons>
          <IonTitle>Mi cuenta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Mi cuenta</IonTitle>
          </IonToolbar>
        </IonHeader>

        <section style={{ maxWidth: 700 }}>
          <AccountDetailsCard/>
          <IonCard>
            <IonList>
              <IonItem
                routerLink="/app/mi-cuenta/actualizar"
                lines="none"
                button
                style={{ paddingBottom: 10 }}
              >
                <IonIcon icon={personOutline} slot="start" />
                <IonLabel>Actualizar datos personales</IonLabel>
              </IonItem>
              <LogoutButton />
            </IonList>
          </IonCard>
        </section>
      </IonContent>
    </IonPage>
  );
};

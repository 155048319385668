import { IonButton, IonItem, IonItemDivider, IonLabel, IonList, IonToggle } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import { findUserProfiles } from "../../../store/userProfiles/actions/findUserProfiles";
import { updateProfilesIdUser } from "../../../store/userProfiles/actions/updateProfilesIdUser";
import { selectListUserProfiles } from "../../../store/userProfiles/selectors/selectUserProfiles";

export const UpdateUserProfileForm: React.FC = () => {

    const { id }: { id: string } = useParams();

    const dispatch = useDispatch();

    const listUserProfiles = useSelector(selectListUserProfiles);

    console.log("listUserProfiles:", listUserProfiles);

    useEffect(() => {
        setData(listUserProfiles);
    }, [listUserProfiles]);

    const [data, setData] = useState(listUserProfiles);

    const onToggleChange = (index: number) => (e: CustomEvent) => {
        const newData = [...data];
        const profile = { ...newData[index] }; // clona el objeto
        profile.isSelected = e.detail.checked; // actualiza la propiedad en la copia
        newData[index] = profile; // actualiza el elemento en el array clonado
        setData(newData); // actualiza el estado con el array clonado
    };

    const onSubmit = async (event:any) => {
        event?.preventDefault();
        //console.log("formulario", data);
        await dispatch(updateProfilesIdUser({ idUser:id, data }));
        window.location.reload();
    };

    //console.log("data:", data);

    return (
        <form onSubmit={onSubmit} style={{ padding: 18 }} noValidate>
            <IonList>
                <IonItemDivider>Privilegios</IonItemDivider>
                {data.map((profile, index) => (
                    <IonItem key={index}>
                        <IonLabel>{profile.name}</IonLabel>
                        <IonToggle checked={profile.isSelected} slot="end" onIonChange={onToggleChange(index)} />
                    </IonItem>
                ))}
            </IonList>
            <IonButton type="submit">
                Guardar cambios
            </IonButton>
        </form>
    )

}
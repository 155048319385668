import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../@types/user";
import { completeRestorePassword } from "./actions/completeRestorePassword";
import createArchive from "./actions/createArchive";
import login from "./actions/login";
import logout from "./actions/logout";
import { requestRecoveryToken } from "./actions/requestRecoveryToken";
import { updateUser } from "./actions/updateUser";
import { validateSession } from "./actions/validateSession";

interface UserAuthenticationState {
  user?: User;
  loginStatus: string;
  logoutStatus: string;
  redirect: string;
  passwordRecoveryStatus: string;
  tempEmail: string;
  error: boolean;
  updateStatus: string;
}

const initialState = {
  user: undefined,
  loginStatus: "initial",
  logoutStatus: "initial",
  redirect: "",
  passwordRecoveryStatus: "initial",
  tempEmail: "",
  error: false,
  updateStatus: "",
} as UserAuthenticationState;

export const initState = createAction("INIT_STATE");
export const setRedirect = createAction<string>("SET_REDIRECT_TO");
export const setPasswordRecoveryStatus = createAction<string>("SET_SHOW_RECOVERY_FORM");
export const setTempEmail = createAction<string>("SET_RECOVERY_EMAIL");
export const resetTemporaryData = createAction("RESET_TEMPORARY_RECOVERY_DATA");
export const setUpdateStatus = createAction("SET_UPDATE_STATUS");
export const setLogoutStatus = createAction<string>("SET_LOGOUT_STATUS");

const userAuthenticationSlice = createSlice({
  name: "userAuthentication",
  initialState,
  reducers: {
    setLoginStatus(state, action: PayloadAction<string>) {
      state.loginStatus = action.payload || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLogoutStatus, (state, { payload }) => {
        state.logoutStatus = payload;
      })
      .addCase(resetTemporaryData, (state) => {
        state.redirect = "";
        state.passwordRecoveryStatus = "initial";
        state.tempEmail = "";
        state.error = false;
      })
      .addCase(initState, (state) => {
        state.loginStatus = "initial";

        state.user = undefined;
        state.logoutStatus = "initial";
      })
      .addCase(setUpdateStatus, (state) => {
        state.updateStatus = "";
      })
      .addCase(setRedirect, (state, { payload }) => {
        state.redirect = payload;
      })
      .addCase(setPasswordRecoveryStatus, (state, { payload }) => {
        state.passwordRecoveryStatus = payload;
      })

      .addCase(login.pending, (state) => {
        state.loginStatus = "pending";
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loginStatus = "fulfilled";
        state.updateStatus = "";

        state.passwordRecoveryStatus = "initial";
        state.tempEmail = "";
      })
      .addCase(login.rejected, (state) => {
        state.loginStatus = "rejected";
      })
      .addCase(logout.pending, (state) => {
        state.logoutStatus = "pending";
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
        state.logoutStatus = "fulfilled";
        state.loginStatus = "initial";
      })
      .addCase(validateSession.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(validateSession.rejected, (state) => {
        state.user = undefined;
        state.logoutStatus = "fulfilled";
        state.loginStatus = "initial";
      })

      .addCase(updateUser.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        if (state.user) {
          state.user.email = payload.email;
        }
        state.updateStatus = "fulfilled";
      })
      .addCase(updateUser.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      .addCase(requestRecoveryToken.fulfilled, (state) => {
        state.passwordRecoveryStatus = "final";
        state.error = false;
      })
      .addCase(requestRecoveryToken.rejected, (state) => {
        state.error = true;
        state.passwordRecoveryStatus = "initial";
        state.tempEmail = "";
      })
      .addCase(completeRestorePassword.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loginStatus = "fulfilled";
        state.error = false;
      })
      .addCase(completeRestorePassword.rejected, (state) => {
        state.error = true;
        state.loginStatus = "final";
      })
      .addCase(setTempEmail, (state, { payload }) => {
        state.tempEmail = payload;
      })
      .addCase(createArchive.fulfilled, (state, { payload }) => {
        if (!state.user) return;
        state.user.profilePicture = payload;
      })
      .addDefaultCase((state, action) => {
        //console.log(action);
      });
  },
});

export default userAuthenticationSlice.reducer;

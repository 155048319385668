import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";

const subscribeNotifications = createAsyncThunk(
  "userAuthentication/subscribeNotifications",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      io.socket.post(
        "/user/subscribe",
        {
          idUser: state.userAuthentication.user?.id,
        },
        () => {}
      );
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default subscribeNotifications;

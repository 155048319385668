import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";

export const validateSession = createAsyncThunk(
  "userAuthentication/validateSession",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { data } = await axios.post(`${api.baseURL}user/validate-session`, {
        userId: state.userAuthentication.user?.id,
      });
      if (!data) {
        throw new Error("Usuario desconectado");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import axios from "axios";
import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { User } from "../../../@types/user";

export const updateUser = createAsyncThunk(
  "userAuthentication/updateUser",
  async (formData: any, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.userAuthentication.user;
      const id = user?.id;
      //console.log('formdata',formData);
      let newData = {};

      if (formData.password) {
        newData = { email: formData.email, password: formData.password, celular: formData.celular };
      } else {
        newData = { email: formData.email, celular: formData.celular };
      } 

      if (formData.workingOrganization) {
        newData = { workingOrganization: formData.workingOrganization };
      }

      const response = await axios.patch(`${api.baseURL}user/${id}`, newData);
      const data: User = response.data;
      return data;
    } catch (error) {
      console.log(error);

      return rejectWithValue(error);
    }
  }
);

import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { findUserProfiles } from "./actions/findUserProfiles";
import { updateProfilesIdUser } from "./actions/updateProfilesIdUser";

interface UserProfilesState {
    userProfiles: any[];
    findStatus: string;
    updateStatus: string;
}

const initialState = {
    userProfiles: [],
    findStatus: "",
    updateStatus: "",
} as UserProfilesState;

export const setUpdateStatus = createAction("SET_UPDATE_STATUS_USER_PROFILES");
export const resetUserProfiles = createAction("RESET_USER_PROFILES");

const userProfilesSlice = createSlice({
    name: "userProfiles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetUserProfiles, (state) => {
                state.userProfiles = [];
                state.findStatus = "";
            })
            .addCase(findUserProfiles.pending, (state) => {
                state.findStatus = "pending";
            })
            .addCase(findUserProfiles.fulfilled, (state, action) => {
                state.userProfiles = action.payload;
                state.findStatus = "fulfilled";
            })
            .addCase(findUserProfiles.rejected, (state) => {
                state.findStatus = "rejected";
            })
            .addCase(updateProfilesIdUser.pending, (state) => {
                state.updateStatus = "pending";
            })
            .addCase(updateProfilesIdUser.fulfilled, (state, action) => {
                state.updateStatus = "fulfilled";
            })
            .addCase(updateProfilesIdUser.rejected, (state) => {
                state.updateStatus = "rejected";
            })
            .addCase(setUpdateStatus, (state) => {
                state.updateStatus = "";
            });
    }
});

export default userProfilesSlice.reducer;
import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  IonGrid,
  IonButton,
  IonText,
  IonCard,
  IonToast,
  IonInput,
  IonIcon
} from "@ionic/react";
import { useParams } from "react-router-dom";
import api from "../../../utils/api";
import axios from "axios";
import { setInitState } from "../../../store/exams";
import { useSelector, useDispatch } from "react-redux";
import { addOutline } from "ionicons/icons";
import { modifyExam } from "../../../store/exams/actions/editExam";

export const EditExam: React.FC = () => {
  const { id }: { id: string } = useParams();
  const dispatch: any = useDispatch();

  const [data, setData] = useState<any>([]);
  const [examName, setExamName] = useState<any>("");
  const [examAbb, setExamAbb] = useState<any>("");
  const [examValue, setExamValue] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const modifyExamStatus = useSelector(
    (state: any) => state.exams.modifyExamStatus
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${api.baseURL}exams?id_exam=${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    dispatch(setInitState());
  }, []);

  const handleEditExam = () => {
    dispatch(
      modifyExam({
        id_exam: data.id,
        name: examName,
        abbreviation: examAbb,
        dollarPrice: examValue,
      })
    );
  };


  useEffect(() => {
    setExamName(data.name || "")
    setExamAbb(data.abbreviation || "")
    setExamValue(data.dollarPrice !== null && data.dollarPrice !== undefined ? data.dollarPrice.toString() : "");
}, [data])
  
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/examenes" color="light" />
          </IonButtons>
          <IonTitle>
            {data ? `Editar Examen: ${data.name}` : "Cargando..."}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="8" sizeLg="6">
                <IonTitle style={{ paddingInline: 0 }}>
                  Modificar Examen
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div style={{}}>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Examen:</IonLabel>
                  <IonInput
                    value={examName}
                    placeholder="Nombre del examen"
                    onIonChange={(e: any) => setExamName(e.detail.value)}
                    type="text"
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Abreviación:</IonLabel>
                  <IonInput
                    value={examAbb}
                    placeholder="Ingrese Abreviación"
                    onIonChange={(e: any) => setExamAbb(e.detail.value)}
                    type="text"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonItem>
                  <IonLabel>Valor: $</IonLabel>
                  <IonInput
                    value={examValue}
                    placeholder="Ingrese Valor en dolares"
                    onIonChange={(e: any) => setExamValue(e.detail.value)}
                    type="number"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 20,
              }}
            >
              <IonCol size="3">
                <IonButton onClick={handleEditExam}>
                  <IonIcon icon={addOutline} slot="start"></IonIcon>
                  <IonText>Modificar Examen</IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </section>
      </IonContent>
      <IonToast
        isOpen={modifyExamStatus === "loading"}
        message="Modificando Examen..."
        position="bottom"
        duration={6000}
        color="primary"
      />

      {/* Success Toast */}
      <IonToast
        isOpen={modifyExamStatus === "succeeded"}
        message="Examen Modificado"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="success"
      />

      {/* Error Toast */}
      <IonToast
        isOpen={modifyExamStatus === "failed"}
        message="A ocurrido un error"
        position="bottom"
        onDidDismiss={() => dispatch(setInitState())} // Reset the state
        duration={6000}
        color="danger"
      />
    </IonPage>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../../@types/user";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";
import axios from "axios";
import { userProfile } from "../../../@types/userProfile";

export const removeUserFromProfile = createAsyncThunk(
  "profileDetail/removeUserFromProfile",
  async (user: User, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userProfileList = state.profileDetail.userProfileList;
      const userProfile = userProfileList.find(
        (item) => item.user?.id === user?.id
      );
      const id = userProfile?.id;
      const response = await axios.delete(`${api.baseURL}userprofile/${id}`);
      const data: userProfile = response.data;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

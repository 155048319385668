import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserRole } from "../../../@types/userRole";
import axios from "axios";
import { RootState } from "../../rootReducer";

export const findUserRoles = createAsyncThunk(
  "supervisorHome/findUserRoles",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { data } = await axios.get(`${api.baseURL}userrole`, {
        params: {
          where: {
            location: state.userAuthentication.user?.locations?.map(({ id }) => id),
          },
          limit: 1000,
        },
      });
      return data as UserRole[];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, { useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { ExamsCalendar } from "./components/ExamsCalendar";
import { useDispatch } from "react-redux";
import { getExams } from "../../store/exams/actions/getExams";
import { getExamsCalendar } from "../../store/exams/actions/getExamsCalendar";
import { getCommunes } from "../../store/exams/actions/getCommunes";
import { getRegions } from "../../store/exams/actions/getRegions";

export const ExamsCalendarMain: React.FC = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getExams());
    dispatch(getExamsCalendar());
    dispatch(getCommunes());
    dispatch(getRegions());
  }, []);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="light" />
          </IonButtons>
          <IonTitle>Calendario de Exámenes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" class="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Calendario de Exámenes</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonButton
            routerLink="/app/calendario-examenes/anadir-calendario"
            size="large"
            color="secondary"
          >
            <IonIcon icon={addOutline} slot="start"></IonIcon>
            <IonText>Añadir Calendario</IonText>
          </IonButton>
        </IonFab>
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="8" sizeLg="6">
                <IonTitle style={{ paddingInline: 0 }}>
                  Calendario de Exámenes
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
          <ExamsCalendar />
          <div style={{ marginTop: 60 }}></div>
        </section>
      </IonContent>
    </IonPage>
  );
};

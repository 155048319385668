import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { findUser } from "../../store/userShow/actions/find-user";
import { SuccessToast } from "./components/SuccessToast";
import { UpdateUserForm } from "./components/UpdateUserForm";

export const UserUpdate: React.FC = () => {
    const dispatch = useDispatch();
    const { id }: { id: string } = useParams();

    useEffect(() => {
        dispatch(findUser(Number(id)));
    }, [dispatch, id]);

    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/app/administrador/usuarios/${id}`} />
                    </IonButtons>
                    <IonTitle>Actualizar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <section style={{ maxWidth: 700 }}>
                    <UpdateUserForm />
                </section>
                <SuccessToast />
            </IonContent>
        </IonPage>
    );
};

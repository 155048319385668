import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React from "react";
import { SuccessToast } from "./components/SuccessToast";
import { UpdateUserForm } from "./components/UpdateUserForm";

export const UserAuthUpdate: React.FC = () => {
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/app/mi-cuenta">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Actualizar</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <section style={{ maxWidth: 700 }}>
          <UpdateUserForm />
        </section>
        <SuccessToast />
      </IonContent>
    </IonPage>
  );
};

import { createSlice, createAction } from "@reduxjs/toolkit";
import { getExams } from "./actions/getExams";
import { getExamsCalendar } from "./actions/getExamsCalendar";
import { getCommunes } from "./actions/getCommunes";
import { getRegions } from "./actions/getRegions";
import { createExamCalendar } from "./actions/createExamCalendar";
import { modifyExamCalendar } from "./actions/modifyExamCalendar";
import { createExam } from "./actions/createExam";
import { modifyExam } from "./actions/editExam";

interface ExamsState {
  exams?: any;
  status?: any;
  error?: any;
  examsCalendar?: any;
  communes?: any;
  regions?: any;
  createExamStatus?: string;
  createExamError?: any;
  modifyExamStatus?: string;
  modifyExamError?:any
}

const initialState = {
  exams: [],
  status: "idle",
  error: null,
  examsCalendar: [],
  communes: [],
  regions: [],
  createExamStatus: "idle",
  createExamError: null,
  modifyExamError:null,
  modifyExamStatus:"idle"
} as ExamsState;

export const setInitState = createAction("SET_NEW_EXAM_CALENDAR_INIT");

const examsSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setInitState, (state) => {
        state.createExamStatus = "idle";
        state.createExamError = null;
        state.modifyExamError = null;
        state.modifyExamStatus = "idle"
      })
      .addCase(getExams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exams = action.payload;
      })
      .addCase(getExams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getExamsCalendar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getExamsCalendar.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.examsCalendar = action.payload;
      })
      .addCase(getExamsCalendar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getCommunes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCommunes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.communes = action.payload;
      })
      .addCase(getCommunes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getRegions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.regions = action.payload;
      })
      .addCase(getRegions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createExamCalendar.pending, (state) => {
        state.createExamStatus = "loading";
      })
      .addCase(createExamCalendar.fulfilled, (state) => {
        state.createExamStatus = "succeeded";
      })
      .addCase(createExamCalendar.rejected, (state, action) => {
        state.createExamStatus = "failed";
        state.createExamError = action.error.message;
      })
      .addCase(createExam.pending, (state) => {
        state.createExamStatus = "loading";
      })
      .addCase(createExam.fulfilled, (state) => {
        state.createExamStatus = "succeeded";
      })
      .addCase(createExam.rejected, (state, action) => {
        state.createExamStatus = "failed";
        state.createExamError = action.error.message;
      })
      .addCase(modifyExamCalendar.pending, (state) => {
        state.modifyExamStatus = "loading";
      })
      .addCase(modifyExamCalendar.fulfilled, (state) => {
        state.modifyExamStatus = "succeeded";
      })
      .addCase(modifyExamCalendar.rejected, (state, action) => {
        state.modifyExamStatus = "failed";
        state.modifyExamError = action.error.message;
      })
      .addCase(modifyExam.pending, (state) => {
        state.modifyExamStatus = "loading";
      })
      .addCase(modifyExam.fulfilled, (state) => {
        state.modifyExamStatus = "succeeded";
      })
      .addCase(modifyExam.rejected, (state, action) => {
        state.modifyExamStatus = "failed";
        state.modifyExamError = action.error.message;
      });
  },
});

export default examsSlice.reducer;

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../rootReducer";

export const resetPassword = createAsyncThunk(
  "userShow/resetPassword",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      await axios.post(`${api.baseURL}user/reset-password`, {
        id: state.userShow.user?.id,
      });
    } catch (error) {
      rejectWithValue(error);
      return error;
    }
  }
);

import { createSlice, createAction } from "@reduxjs/toolkit";
import { getInscriptions } from "./actions/getInscriptions";
import { modifyInscription } from "./actions/modifyInscription";
import { getCommuneWithExams } from "./actions/getCommunesWithExams";
import { postInscriptionData } from "./actions/postData";

interface InscriptionsState {
  inscriptions?: any;
  status?: any;
  error?: any;
  modifyInscriptionStatus?: string;
  modifyInscriptionError?: any;
  communesWithExams?:any[];
  postStatus?:string
}

const initialState = {
  inscriptions: [],
  status: "idle",
  error: null,
  modifyInscriptionError: null,
  modifyInscriptionStatus: "idle",
  communesWithExams:[],
  postStatus:"idle"
} as InscriptionsState;

export const setInitState = createAction("SET_EDIT_INSCRIPTION_INIT");


const inscriptionsSlice = createSlice({
  name: "inscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setInitState, (state) => {
        state.modifyInscriptionError = null;
        state.modifyInscriptionStatus = "idle";
        state.postStatus = "idle"
      })
      .addCase(getInscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.inscriptions = action.payload;
        state.error = null;
      })
      .addCase(getInscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getCommuneWithExams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCommuneWithExams.fulfilled, (state, action) => {
        state.communesWithExams = action.payload
      })
      .addCase(getCommuneWithExams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(modifyInscription.pending, (state) => {
        state.modifyInscriptionStatus = "loading";
      })
      .addCase(modifyInscription.fulfilled, (state) => {
        state.modifyInscriptionStatus = "succeeded";
      })
      .addCase(modifyInscription.rejected, (state, action) => {
        state.modifyInscriptionStatus = "failed";
        state.modifyInscriptionError = action.error.message;
      })
      .addCase(postInscriptionData.pending, (state) => {
        state.postStatus = "loading"
      })
      .addCase(postInscriptionData.fulfilled, (state) => {
        state.postStatus = "success"
      })
      .addCase(postInscriptionData.rejected, (state, action) => {
        state.postStatus = "failed"
      })
      ;
  },
});

export default inscriptionsSlice.reducer;

import {
  IonButtons,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../store/userAuthentication/selectors/selectUser";
import UserAvatar from "../components/UserAvatar";
import { selectAppPages } from "../store/userAuthentication/selectors/selectAppPages";

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUser);
  const appPages = useSelector(selectAppPages);

  const styles = {
    menu: { "--background": "#1B243A" },
    transparent: { background: "transparent", "--background": "transparent" },
    text: { color: "#fff" },
    title: { fontSize: "12px" },
    content: { fontSize: "14px" },
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent style={{ ...styles.menu }}>
        <IonItem lines="full" color="primary">
          <UserAvatar user={user}></UserAvatar>
          <IonLabel>
            <h2 style={{ fontWeight: 500, fontSize: "14px" }}>{user?.name}</h2>
            <h4 style={{ ...styles.title }}>{user?.email}</h4>
          </IonLabel>
        </IonItem>
        <IonList style={{ ...styles.transparent }} color="transparent">
          {appPages
            .filter(({ menu }) => menu)
            .map((appPage, index) => (
              <IonMenuToggle key={index} auto-hide="false">
                <IonItem
                  color={
                    location.pathname.includes(appPage.url)
                      ? "#23304C"
                      : "transparent"
                  }
                  routerLink={appPage.url}
                  lines="none"
                  detail={false}
                  style={{
                    borderRadius: "4px",
                    marginBottom: "4px",
                    paddingLeft: 30,
                    ...styles.text,
                    borderLeft: location.pathname.includes(appPage.url)
                      ? "4px solid #3DA2FF"
                      : "none",
                    backgroundColor: location.pathname.includes(appPage.url)
                      ? "#23304C"
                      : "transparent",
                  }}
                >
                  <IonIcon
                    slot="start"
                    icon={appPage.icon}
                    style={{ marginRight: 15, ...styles.text, width: 20 }}
                  />
                  <IonLabel>
                    <h2>{appPage.title}</h2>
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonCheckbox,
  IonNote,
  IonText,
  IonInput,
  IonCard,
  IonButton,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import {
  setErrors,
  setFormValidity,
  setIsSameBuyer,
  setPurchaseConf,
} from "../../../store/userInscriptions";
import { format, validate } from "rut.js";
import api from "../../../utils/api";

interface ConfirmPaymentProps {
  check?: any;
  setCheck?: any;
  selectedRegion?: any;
  setSelectedRegion?: any;
  selectedCommune?: any;
  setSelectedCommune?: any;
  rut?: any;
  setRut?: any;
  name?: any;
  setName?: any;
  email?: any;
  setEmail?: any;
  discount?: any;
  setDiscount?: any;
  totalAmount1?:any;
  setTotalAmount?:any;
  discountAmount?:any;
  setDiscountAmount?:any
}

export const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({
  check,
  setCheck,
  selectedRegion,
  setSelectedRegion,
  selectedCommune,
  setSelectedCommune,
  rut,
  setRut,
  name,
  setName,
  email,
  setEmail,
  discount,
  setDiscount,
  totalAmount1,
  setTotalAmount,
  discountAmount,
  setDiscountAmount
}: any) => {
  const dispatch: any = useDispatch();
  const allCommunes = useSelector((state: any) => state.exams?.communes);
  const allRegions = useSelector((state: any) => state.exams?.regions);
  const errors = useSelector((state: any) => state.userInscription.errors);
  const showErrors = useSelector(
    (state: any) => state.userInscription.showErrors
  );

  const allExamsCalendars = useSelector(
    (state: any) => state.exams?.examsCalendar
  );
  const allExams = useSelector((state: any) => state.exams?.exams);

  const selectedExamsForPurchase = useSelector(
    (state: any) => state.userInscription.inscriptionCalendar?.examsForPurchase
  );

  const [isValidRut, setIsValidRut] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleCheckboxChange = (e: CustomEvent) => {
    const newCheckValue = e.detail.checked;
    if (setCheck) {
      setCheck(newCheckValue);
    }
  };

  const handleChange = (e: any) => {
    const inputValue = e.detail.value;
    const cleanRut = format(inputValue);
    setRut(cleanRut);
    setIsValidRut(validate(cleanRut));
  };

  const validateInputs = () => {
    const isValidName = name.length > 0;

    const isValidRegion = allRegions.some(
      (region: any) => region.id === selectedRegion
    );

    const isValidCommune = allCommunes.some(
      (commune: any) =>
        commune.id === selectedCommune && commune.id_region === selectedRegion
    );

    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );

    const errors = {
      names: isValidName ? null : "Ingrese un Nombre",
      selectedRegion: isValidRegion ? null : "Seleccione Región",
      selectedCommune: isValidCommune ? null : "Seleccione Comuna",
      email: isValidEmail ? null : "Email Invalido",
    };

    const isFormValid =
      isValidRut &&
      isValidName &&
      isValidRegion &&
      isValidCommune &&
      isValidEmail;

    dispatch(setFormValidity(isFormValid));
    dispatch(setErrors(errors));
    dispatch(
      setPurchaseConf({
        name: name,
        rut: rut,
        email: email,
        region: selectedRegion,
        commune: selectedCommune,
      })
    );
  };

  useEffect(() => {
    validateInputs();
  }, [name, rut, selectedRegion, selectedCommune, email]);

  useEffect(() => {
    dispatch(setIsSameBuyer(check));
    if (!check) {
      validateInputs();
    } else {
      dispatch(setErrors({}));
      dispatch(setFormValidity(true));
    }
  }, [check]);

  const cellStyle = {
    border: "1px solid #cccccc",
    textAlign: "center",
    margin: 1,
    minWidth: "19%",
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  };

  const examsPrice = selectedExamsForPurchase
    .map(
      (selectedExamId: number) =>
        allExamsCalendars.find(
          (calendar: any) => calendar.id === selectedExamId
        )?.price || 0
    )
    .reduce((total: number, price: number) => total + price, 0);

  const totalAmount =
    selectedExamsForPurchase
      .map(
        (selectedExamId: number) =>
          allExamsCalendars.find(
            (calendar: any) => calendar.id === selectedExamId
          )?.price || 0
      )
      .reduce((total: number, price: number) => total + price, 0) -
    discountAmount;

    useEffect(() => {
      setTotalAmount(totalAmount)
    }, [totalAmount])


  const verifyDiscount = async () => {
    setIsLoading(true);
    setError("");
    setSuccess("");
    try {
      const response = await fetch(
        `${api.baseURL}discount_code?code=${discount}`
      );

      if (!response.ok) {
        setError("Código Expirado/Invalido");
        setDiscountAmount(0);
      }
      const data = await response.json();

      if (data.isUsed === false) {
        setDiscountAmount(data.discount_percent * examsPrice);
        setSuccess("Descuento aplicado");
      } else {
        setError("Código Invalido");
        setDiscountAmount(0);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonGrid>
      <IonRow style={{ marginTop: 40, marginBottom: 20 }}>
        <IonCol>
          <IonTitle>Detalle de Compra</IonTitle>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonCheckbox
              slot="start"
              checked={check}
              onIonChange={handleCheckboxChange}
            />
            <IonLabel>
              La persona que rendirá el examen es la misma que paga?
            </IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem style={{ marginBottom: 10 }}>
            <IonInput
              placeholder="Nombre"
              type="text"
              value={name}
              onIonChange={(e: any) => setName(e.detail.value)}
              disabled={check}
            />
            {showErrors && errors.names && (
              <IonNote color="danger">{errors.names}</IonNote>
            )}
          </IonItem>
          <IonItem style={{ marginBottom: 10 }}>
            <IonInput
              value={rut}
              placeholder="Rut"
              type="text"
              onIonChange={handleChange}
              disabled={check}
            />
            {!isValidRut && <div style={{ color: "red" }}>RUT no valido</div>}
          </IonItem>
          <IonItem style={{ marginBottom: 10 }}>
            <IonInput
              placeholder="Correo"
              type="text"
              value={email}
              onIonChange={(e: any) => setEmail(e.detail.value)}
              disabled={check}
            />
            {showErrors && errors.email && (
              <IonNote color="danger">{errors.email}</IonNote>
            )}
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem style={{ marginBottom: 10 }}>
            <IonLabel style={{ display: "none" }}>Región</IonLabel>
            <IonSelect
              value={selectedRegion}
              placeholder="Seleccione Región"
              onIonChange={(e: any) =>
                setSelectedRegion(Number(e.detail.value))
              }
              disabled={check}
            >
              {allRegions.map((region: any) => (
                <IonSelectOption key={region.id} value={region.id}>
                  {region.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            {showErrors && errors.selectedRegion && (
              <IonNote color="danger">{errors.selectedRegion}</IonNote>
            )}
          </IonItem>
          <IonItem style={{ marginBottom: 10 }}>
            <IonLabel style={{ display: "none" }}>Comuna</IonLabel>
            <IonSelect
              value={selectedCommune}
              placeholder="Seleccione Comuna"
              onIonChange={(e: any) => setSelectedCommune(e.detail.value)}
              style={{ width: "100%" }}
              disabled={check}
            >
              {allCommunes
                .filter((commune: any) => commune.id_region === selectedRegion)
                .map((commune: any) => (
                  <IonSelectOption key={commune.id} value={commune.id}>
                    {commune.name}
                  </IonSelectOption>
                ))}
            </IonSelect>
            {showErrors && errors.selectedCommune && (
              <IonNote color="danger">{errors.selectedCommune}</IonNote>
            )}
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow style={{ marginTop: 20 }}>
        <IonCol>
          <IonRow>
            <IonCol>
              <IonTitle>Exámenes Seleccionados</IonTitle>
            </IonCol>
          </IonRow>
          <IonCard>
            <IonRow style={headerStyle}>
              <IonCol style={headerStyle}>Examen</IonCol>
              <IonCol style={headerStyle}>Precio</IonCol>
            </IonRow>
            {selectedExamsForPurchase.map((selectedExamId: number) => {
              const examCalendar = allExamsCalendars.find(
                (calendar: any) => calendar.id === selectedExamId
              );
              const exam = allExams.find(
                (exam: any) => exam.id === examCalendar?.id_exam
              );
              return (
                <IonRow key={selectedExamId}>
                  <IonCol style={cellStyle}>{exam?.abbreviation}</IonCol>
                  <IonCol style={cellStyle}>${examCalendar?.price}</IonCol>
                </IonRow>
              );
            })}
          </IonCard>
        </IonCol>
        <IonCol style={{ marginLeft: 20 }}>
          <IonTitle>Total a pagar: $ {totalAmount1}</IonTitle>
          <IonItem style={{ marginTop: 10 }} lines="none">
            <IonLabel>Tienes un código de descuento?</IonLabel>
          </IonItem>
          <IonItem style={{ width: "50%", marginTop: 10 }}>
            <IonInput
              placeholder="Código de descuento"
              value={discount}
              onIonChange={(e: any) => setDiscount(e.detail.value)}
            />
            <IonButton onClick={verifyDiscount} disabled={isLoading}>
              {isLoading ? "Verificando..." : "Aplicar"}
            </IonButton>
          </IonItem>
          <IonNote color="danger">{error}</IonNote>
          <IonNote color="success">{success}</IonNote>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

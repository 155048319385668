import React from "react";
import { IonGrid, IonRow, IonCol, IonSpinner, IonLoading } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ExamsList: React.FC = () => {
  const cellStyle = {
    border: "1px solid #cccccc",
    textAlign: "center",
    margin: 1,
    minWidth: "19%",
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  };

  const allExams = useSelector((state: any) => state.exams?.exams);
  const status = useSelector((state: any) => state.exams?.status);

  return (
    <IonGrid>
      <IonRow style={headerStyle}>
        <IonCol style={headerStyle}>Nombre</IonCol>
        <IonCol style={headerStyle}>Abreviación</IonCol>
        <IonCol style={headerStyle}>Precio USD</IonCol>
        <IonCol style={headerStyle}>Acciones</IonCol>
      </IonRow>
      {status === "succeeded" ? (
        allExams.map((item: any, index: any) => (
          <IonRow key={index}>
            <IonCol style={cellStyle}>{item.name}</IonCol>
            <IonCol style={cellStyle}>{item.abbreviation}</IonCol>
            <IonCol style={cellStyle}>$ {item.dollarPrice}</IonCol>
            <IonCol style={cellStyle}>
            <Link to={{
              pathname: `/app/examenes/editar-examen/${item.id}`
              }}>Editar</Link>
            </IonCol>
          </IonRow>
        ))
      ) : (
        <IonLoading
          isOpen={status === "loading"}
          message={"Cargando Listado..."}
        />
      )}
    </IonGrid>
  );
};

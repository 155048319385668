import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Message } from "../../../@types/message";
import axios from "axios";
import { RootState } from "../../rootReducer";
export const findMessages = createAsyncThunk(
  "supervisorHome/findMessages",
  async (searchText, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { data } = await axios.get(`${api.baseURL}message`, {
        params: {
          sort: "createdAt DESC",
          where: {
            or: state.userAuthentication.user?.locations?.map(({ id }) => ({
              locations: { contains: id },
            })),
          },
          populate: ["createdBy", "document"].join(","),
        },
      });
      return data as Message[];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

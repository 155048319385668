import { useSelector } from "react-redux";
import { IonTitle, IonToolbar } from "@ionic/react";
import { selectUser } from "../../../store/userShow/selectors/selectUser";
import UserAvatar from "../../../components/UserAvatar";
export const HeaderSection: React.FC = () => {
  const user = useSelector(selectUser);
  return (
    <>
      <IonToolbar class="ion-padding-bottom">
        <section className="ion-padding">
          <UserAvatar user={user}></UserAvatar>
        </section>
        <IonTitle>{user?.name}</IonTitle>
      </IonToolbar>
    </>
  );
};

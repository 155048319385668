import {
  IonCard,
  IonAvatar,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { cloudUploadOutline } from "ionicons/icons";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../components/UserAvatar";
import createArchive from "../../../store/userAuthentication/actions/createArchive";
import { selectUser } from "../../../store/userAuthentication/selectors/selectUser";
import api from "../../../utils/api";

export const AccountDetailsCard = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const onFilechange = async (event: any) => {
    await dispatch(createArchive(event.target.files[0]));
    event.target.value = null;
  };

  return (
    <IonCard color="secondary">
      <section style={{ display: "flex", padding: 12 }}>
        <UserAvatar user={user}></UserAvatar>
      </section>
      <IonCardHeader>
        <IonCardTitle>{user?.name}</IonCardTitle>
        <IonCardSubtitle>{user?.email}</IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};

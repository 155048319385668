import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const importUserOrganization = createAsyncThunk(
    "userCreate/importUserOrganization",
    async (info:any, { rejectWithValue }) => {
        const idUser = info.userId.toString();
        const organizationId = info.sourceOrganization.toString();
        try {
            let response = axios.post(`${api.baseURL}userOrganization/set-source-organization`, {
                userId:idUser,
                sourceOrganization:organizationId
            });
            let data = await response;
            return data.data;
        }catch (err) {
            return rejectWithValue(err);
        }
    }
)
import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

export const selectUsersFiltered = createSelector(
  [(store: RootState) => store.users.users],
  (users) => {
    return users;
  }
  
);

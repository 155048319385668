import React from "react";
import { IonGrid, IonRow, IonCol, IonSpinner, IonLoading } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface InscriptionTableProps {
  data?: any;
}

export const InscriptionsTable: React.FC<InscriptionTableProps> = ({
  data,
}: any) => {
  const cellStyle = {
    border: "1px solid #cccccc",
    textAlign: "center",
    margin: 1,
    minWidth: "19%",
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  };

  const status = useSelector((state: any) => state.inscriptions?.status) ?? "";

  // Function to format date
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  if (data && data.length > 0) {
    data = [...data].sort((a, b) => {
      const aDate: any = new Date(a.calendar[0]?.date);
      const bDate: any = new Date(b.calendar[0]?.date);
      return bDate - aDate;
    });
  }

  return (
    <IonGrid>
      <IonRow style={headerStyle}>
        <IonCol style={headerStyle}>Candidato</IonCol>
        <IonCol style={headerStyle}>Fecha de Inscripción</IonCol>
        <IonCol style={headerStyle}>Examen</IonCol>
        <IonCol style={headerStyle}>Estado de Pago</IonCol>
        <IonCol style={headerStyle}>Acciones</IonCol>
      </IonRow>
      {status === "succeeded" && data ? (
        data.map((item: any, index: any) => (
          <IonRow key={index}>
            <IonCol style={cellStyle}>
              { item.inscription.name + " " + item.inscription.last_name}
            </IonCol>
            <IonCol style={cellStyle}>
              {item.inscription && item.inscription?.date ? formatDate(item.inscription?.date) : "--/--/--"}
            </IonCol>
            <IonCol style={cellStyle}>
              {item.calendar && item.calendar.length > 0 ?
                Array.from(
                  new Set(
                    item.calendar.flatMap((calendar: any) =>
                      calendar.exam?.abbreviation)
                    )
                  )
                .join(", ") : "Sin Examen Inscrito"}
            </IonCol>
            <IonCol style={cellStyle}>
              {item.payment?.isPaid ? "Pagado" : item.payment?.isPaid === false ? "Pendiente" : "No Disponible"}
            </IonCol>
            <IonCol style={cellStyle}>
              <Link
                to={{
                  pathname: `/app/inscripciones/editar-ficha/${item.inscription.id}`,
                  state: { item },
                }}
              >
                Editar
              </Link>
            </IonCol>
          </IonRow>
        ))
      ) : (
        <IonLoading
          isOpen={status === "loading"}
          message={"Cargando Datos..."}
        />
      )}
    </IonGrid>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const findUser = createAsyncThunk(
  "userShow/findUser",
  async (id: number, { rejectWithValue }) => {
    try {
      const url = new URL(`${api.baseURL}user/${id}`);
      let response = await fetch(url.toJSON());
      if (!response.ok) {
        throw response.statusText;
      }
      let data = await response.json();
      if (!data) {
        throw new Error("No se encontró");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

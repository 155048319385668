import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

export const Header = () => {
  return (
    <IonHeader class="ion-no-border">
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            text={isPlatform("ios") ? "Ingresar" : ""}
            default-href="/ingresar"
            color="light"
          />
        </IonButtons>
        <IonTitle>Recuperar contraseña</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../rootReducer";
import { User } from "../../../@types/user";

export const updateUser = createAsyncThunk(
    "userShow/updateUser",
    async (params: User, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            await axios.patch(`${api.baseURL}user/${state.userShow.user?.id}`, {
                ...params,
            });
        } catch (error) {
            rejectWithValue(error);
            return error;
        }
    }
)
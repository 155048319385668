import { createAction, createSlice } from "@reduxjs/toolkit";
import { createUser } from "./actions/createUser";
interface UserCreateState {
  user: any;
  createStatus: string;
}

const initialState = {
  user: {},
  createStatus: "",
} as UserCreateState;

export const setCreateStatus = createAction("SET_CREATE_STATUS_USER_CREATE");
export const resetUserCreate = createAction("RESET_USER_CREATE");

const userCreateSlice = createSlice({
  name: "userCreate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetUserCreate, (state) => {
        state.user = {};
        state.createStatus = "";
      })
      .addCase(createUser.pending, (state, { payload }) => {
        state.createStatus = "pending";
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.createStatus = "fulfilled";
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.createStatus = "rejected";
      })
      .addCase(setCreateStatus, (state) => {
        state.createStatus = "";
      });
  },
});

export default userCreateSlice.reducer;

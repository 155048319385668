import api from "../../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCommuneWithExams = createAsyncThunk(
    "inscriptions/getCommunesWithExams",
    async (_, { rejectWithValue }) => {
        try {

            const response = axios.get(`${api.baseURL}calendar_exam_commune`);

            const data = await response;

            return data.data;

        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../utils/api";
import { RootState } from "../../rootReducer";

export const completeRestorePassword = createAsyncThunk(
  "userAuthentication/completeRestorePassword",
  async (data: any, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const email = state.userAuthentication.tempEmail;
      const { password, token } = data;

      if (!email) throw new Error("El correo no existe.");

      const response = await axios.post(`${api.baseURL}user/complete-restore-password`, {
        password,
        email,
        token,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
